import { all } from 'redux-saga/effects';
import siteSaga from '../controllers/_SiteController/SiteSaga';
import textEditorSaga from '../controllers/TextEditorController/TextEditorSaga';
import ticketDetailSaga from '../controllers/TicketController/TicketSaga';
import socketsSaga from '../controllers/SocketsController/SocketsSaga';

function* rootSaga(): any {
    yield all([
        ...siteSaga,
        ...ticketDetailSaga,
        ...textEditorSaga,
        ...socketsSaga,
    ]);
}

export default rootSaga;
