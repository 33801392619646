import styles from './FileComponent.module.css';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import Loader from '../Loader/Loader';
import { useState } from 'react';
import React from 'react';
import {
    FileObject,
    UploadedFileObject,
} from '../../controllers/TextEditorController/TextEditorReducer';
import { PayloadFile } from '../../@Types/Payload';

interface FileComponentProps {
    file: FileObject | UploadedFileObject | PayloadFile;
    error?: boolean;
    handleRemove?: Function;
    marginLeft?: string;
    showToolTip?: boolean;
    color?: string;
}

function FileUploadComponent({
    file,
    error,
    showToolTip,
    color = 'var(--secondary)',
    handleRemove,
    marginLeft = '1px',
}: FileComponentProps): JSX.Element {
    const [downloading, setDownloading] = useState(false);
    if (!file) {
        return <div></div>;
    }

    const downloadFile = async (): Promise<void> => {
        try {
            setDownloading(true);
            let fileUrl = (file as PayloadFile).downloadUrl;
            if (fileUrl && file.fileName) {
                var a = document.createElement('a');
                a.href = fileUrl;
                a.setAttribute('download', file.fileName);
                a.click();
            }
            setDownloading(false);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
        }
    };

    const calcIcon = (): JSX.Element => {
        if (downloading) {
            return (
                <div className={styles.loaderContainer}>
                    <Loader size={26} color={color} />
                </div>
            );
        } else if ((file as FileObject).state !== undefined) {
            switch ((file as FileObject).state) {
                case 'FETCHING':
                case 'UPLOADING':
                    return (
                        <div className={styles.loaderContainer}>
                            <Loader size={26} color={color} />
                        </div>
                    );
                case 'DONE':
                    return (
                        <div className={styles.documentIcon} style={{ color }}>
                            <DescriptionRoundedIcon />
                        </div>
                    );
                case 'ERROR':
                    return (
                        <div
                            className={styles.documentIcon}
                            style={{ color: 'var(--error)' }}
                        >
                            <ErrorRoundedIcon />
                        </div>
                    );
                default:
                    return <div></div>;
            }
        } else {
            return (
                <div className={styles.documentIcon} style={{ color }}>
                    <DescriptionRoundedIcon />
                </div>
            );
        }
    };
    return (
        <div>
            <div className={styles.container} style={{ marginLeft }}>
                <div className={styles.iconContainer}>{calcIcon()}</div>
                <div
                    title={showToolTip ? file.fileName : ''}
                    className={
                        file.S3Key ? styles.downloadableLbl : styles.label
                    }
                    style={{
                        color: error ? 'var(--error)' : 'var(--accent)',
                    }}
                    onClick={(): void => {
                        downloadFile();
                    }}
                >
                    {(file as FileObject).file?.name ?? file.fileName}
                </div>
                {handleRemove !== undefined && (
                    <div
                        className={styles.deletBtn}
                        onClick={(): void => {
                            handleRemove();
                        }}
                    >
                        {(file as FileObject).state !== undefined && (
                            <ClearRoundedIcon fontSize="inherit" />
                        )}
                    </div>
                )}
            </div>
            {(file as FileObject).state === 'ERROR' &&
                (file as FileObject).errorMsg && (
                    <div className={styles.errorMsg}>
                        {(file as FileObject).errorMsg}
                    </div>
                )}
        </div>
    );
}

export default FileUploadComponent;
