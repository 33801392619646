import { takeLatest, fork, call, put, select } from 'redux-saga/effects';
import * as actions from './TicketActions';
import * as SiteActions from '../_SiteController/SiteActions';
import { AnyAction } from 'redux';
// import LogRocket from 'logrocket';
import { fetchTicket } from './TicketService';
import { RootState } from '../../utils/_store';
import { TicketDetailState } from './TicketReducer';
import * as socketActions from '../SocketsController/SocketsActions';

/**
 * Function called when the load action is triggered
 * @param action of the loadTokens
 */
function* load(action: AnyAction): any {
    if (actions.reset.match(action)) {
        try {
            const ticket = yield call(fetchTicket, action.payload.idTicket);
            const { branding, organization } = yield select(
                (state: RootState) => state.site
            );
            const domain = window.location.hostname.split('.')[0];
            //Identify the current ticket in logrocket
            if (process.env.NODE_ENV === 'production' && ticket) {
                // LogRocket.identify(
                //     domain + '/' + (ticket?.caseNumber ?? 'NOT_FOUND'),
                //     {
                //         organization: domain ?? 'NO_ORG',
                //         caseNumber: ticket?.caseNumber ?? 'NOT_FOUND',
                //     }
                // );
            }
            if (!branding || ticket.branding)
                yield put(
                    SiteActions.setBranding(
                        ticket.branding ?? organization.branding
                    )
                );
            if (ticket._id && domain) {
                yield put(
                    socketActions.initiateSocket({
                        idTicket: ticket._id,
                        idOrganization: domain,
                    })
                );
            }

            yield put(
                actions.getSuccess({
                    ticket,
                })
            );
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            yield put(actions.resetError());
        }
    }
}

/**
 * Function called when the user adds a reply to the ticket
 * @param action of the loadTokens
 */
function* refresh(action: AnyAction): any {
    if (actions.refresh.match(action)) {
        try {
            const ticketInfo: TicketDetailState = yield select(
                (state: RootState) => state.ticketDetail
            );

            const ticket = yield call(
                fetchTicket,
                ticketInfo.ticket?._id ?? ''
            );
            if (ticketInfo.ticket) {
                yield put(
                    actions.getSuccess({
                        ticket,
                    })
                );
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            yield put(actions.resetError());
        }
    }
}

function* watchLoad(): any {
    yield takeLatest([actions.Types.RESET], load);
}

function* watchRefresh(): any {
    yield takeLatest([actions.Types.REFRESH], refresh);
}

export default [fork(watchLoad), fork(watchRefresh)];
