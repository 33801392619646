import { createAction } from '@reduxjs/toolkit';
import { Ticket } from '../../@Types/@Types';

export const Types = {
    RESET: 'TICKET_DETAIL/RESET',
    REFRESH: 'TICKET_DETAIL/REFRESH',
    GET_SUCCESS: 'TICKET_DETAIL/GET_SUCCESS',
    RESET_ERROR: 'TICKET_DETAIL/RESET_ERROR',
    SET_IMG_MAX_WIDTH: 'TICKET_DETAIL/SET_IMG_MAX_WIDTH',
    LOADING: 'TICKET_DETAIL/LOADING',
    SHOW_RATING_CONFIRM: 'TICKET_DETAIL/SHOW_RATING_CONFIRM',
    SHOW_POSTVIEW: 'TICKET_DETAIL/SHOW_POSTVIEW',
};

/**
 * Called when a ticket is loaded, sends the id if the ticket to load and if it should display the form
 */
export const reset = createAction<{ idTicket: string; showForm?: boolean }>(
    Types.RESET
);
/**
 * Called when a ticket is loaded, sends the id if the ticket to load and if it should display the form
 */
export const refresh = createAction(
    Types.REFRESH,
    function prepare(loading: boolean = true) {
        return {
            payload: loading,
        };
    }
);

/**
 * Called after the ticket is reset.
 */
export const getSuccess = createAction<{
    ticket: Ticket;
}>(Types.GET_SUCCESS);

/**
 * Called to toggle the loader
 */
export const setLoading = createAction(
    Types.LOADING,
    function prepare(loading: boolean = true) {
        return {
            payload: loading,
        };
    }
);

/**
 * Called to toggle the loader
 */
export const setShowRatingConfirmation = createAction(
    Types.SHOW_RATING_CONFIRM,
    function prepare(loading: boolean = true) {
        return {
            payload: loading,
        };
    }
);

/**
 * Called to toggle the postview dialog
 */
export const setShowPostview = createAction(
    Types.SHOW_POSTVIEW,
    function prepare(loading: boolean = true) {
        return {
            payload: loading,
        };
    }
);

/**
 * Called when the ticket failed to load
 */
export const resetError = createAction(Types.RESET_ERROR);

/**
 * Called to set the current img Max width
 */
export const setImgMaxWidth = createAction<number>(Types.SET_IMG_MAX_WIDTH);
