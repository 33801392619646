import axiosInstance from '../../AxiosAPI';
import axios from 'axios';

export async function getUploadUrls(
    files: Array<File>
): Promise<(Record<string, any> | string)[]> {
    const payload = files.map((file: File) => ({
        publicFile: false,
        extension: file?.name?.split?.('.')?.pop(),
    }));

    const response = await axiosInstance.post('/uploadfile', payload);

    return response.data ?? undefined;
}

export async function postFile(
    file: File,
    postInfo: Record<string, any>
): Promise<{ fileName: string; S3Key: string }> {
    const formData = new FormData();
    Object.keys(postInfo.postInfo.fields).forEach((key) => {
        formData.append(key, postInfo.postInfo.fields[key]);
    });

    // Actual file has to be appended last.
    formData.append('file', file);

    await axios.post(postInfo.postInfo.url, formData, {
        timeout: 300000,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });

    return {
        fileName: file.name,
        S3Key: postInfo.S3Key,
    };
}
