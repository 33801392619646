import Avatar from '../../../../shared/Avatar/Avatar';

interface ActionAvatarProps {
    img: string | undefined;
    name: string;
}

/**
 * Component used to display the agent that made an action
 */
function ActionAvatar({ img, name }: ActionAvatarProps): JSX.Element {
    return <Avatar img={img} lbl={name} toolTip={true} />;
}

export default ActionAvatar;
