import styles from '../Rating.module.css';
import { IconContainerProps, Rating } from '@material-ui/lab';
import GradeRoundedIcon from '@material-ui/icons/GradeRounded';
import { RatingProps } from '../Rating';
import { makeStyles } from '@material-ui/core';
import React from 'react';

interface ColorProps {
    /** The color of the outline when selected and hovered on */
    focusColor?: string;
    /** The color of the icons when it is not selected */
    unSelectedColor?: string;
}

const useRatingStyles = (props: ColorProps): Function =>
    makeStyles({
        root: {
            '&.Mui-disabled': {
                opacity: 1,
            },
            '& .ErkC-MuiRating-iconFilled': {
                color: props.focusColor,
            },
            '& .ErkC-MuiRating-iconEmpty': {
                color: props.unSelectedColor,
            },
        },
    });

function ScaleRating({
    name,
    value,
    inputRef,
    disabled,
    isAction,
    onChange,
    isMobile,
    focusColor,
    unSelectedColor,
}: RatingProps): JSX.Element {
    const ratingClasses = useRatingStyles({
        unSelectedColor,
        focusColor,
    })();
    return (
        <React.Fragment>
            <input ref={inputRef} className={styles.hiddenInput} />
            <div className={styles.scaleContainer}>
                <Rating
                    value={value}
                    onChange={(_event, newValue): void => {
                        if (!disabled) onChange(newValue);
                    }}
                    disabled={disabled === true}
                    classes={ratingClasses}
                    name={name}
                    size="large"
                    defaultValue={0}
                    getLabelText={(value: number): string => value + '/5'}
                    max={5}
                    IconContainerComponent={
                        isMobile || isAction
                            ? SmallIconContainer
                            : IconContainer
                    }
                />
            </div>
        </React.Fragment>
    );
}

function IconContainer({ value, ...other }: IconContainerProps): JSX.Element {
    return (
        <span className={'icon' + value} {...other}>
            <GradeRoundedIcon style={{ fontSize: 60 }} />
        </span>
    );
}

function SmallIconContainer({
    value,
    ...other
}: IconContainerProps): JSX.Element {
    return (
        <span className={'icon' + value} {...other}>
            <GradeRoundedIcon style={{ fontSize: 40 }} />
        </span>
    );
}
export default ScaleRating;
