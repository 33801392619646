import { combineReducers, Action } from 'redux';
import siteReducer from '../controllers/_SiteController/SiteReducer';
import TextEditorReducer from '../controllers/TextEditorController/TextEditorReducer';
import TicketDetailReducer from '../controllers/TicketController/TicketReducer';
import socketsReducer from '../controllers/SocketsController/SocketsReducer';

const reducers = combineReducers({
    site: siteReducer,
    ticketDetail: TicketDetailReducer,
    textEditor: TextEditorReducer,
    socketsReducer: socketsReducer,
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function rootReducer(state: any, action: Action) {
    return reducers(state, action);
}

export default rootReducer;
