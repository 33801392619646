import styles from './ProcessRenderer.module.css';
import { Ticket } from '../../@Types/@Types';
import ProcessElement from './ProcessItem/ProcessElement';

interface ProcessRendererProps {
    /** The ticket to display */
    ticket: Ticket;
}

/**
 * Generic Ticket Process Renderer
 */
function ProcessRenderer({ ticket }: ProcessRendererProps): JSX.Element {
    if (!ticket.process?.visualElements) return <div></div>;

    return (
        <div className={styles.container}>
            {ticket.process.visualElements.map((element, index) => (
                <ProcessElement
                    key={index}
                    element={element}
                    last={index === ticket.process!.visualElements.length - 1}
                />
            ))}
        </div>
    );
}

export default ProcessRenderer;
