import LogRocket from 'logrocket';
import { applyMiddleware, createStore, Middleware, StoreEnhancer } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import 'regenerator-runtime/runtime';
import rootReducer from './_reducer';
import rootSaga from './_saga';

const bindMiddleware = (middleware: Middleware[]): StoreEnhancer => {
    if (process.env.NODE_ENV === 'production') {
        return applyMiddleware(...middleware);
    }
    return composeWithDevTools(applyMiddleware(...middleware));
};

const sagaMiddleware = createSagaMiddleware();
//TODO: LOAD persisted ticket filters for each project from local storage.
const store = createStore(
    rootReducer,
    bindMiddleware([sagaMiddleware, LogRocket.reduxMiddleware()])
);
sagaMiddleware.run(rootSaga);
export default store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
