import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';

interface StyleProps {
    /** The color of the text */
    textColor?: string;
    /** The color of the outline when selected and hovered on */
    focusColor?: string;
    /** The color of the error to display */
    errorColor?: string;
    /** The color of the outline when it is not selected */
    outlineColor?: string;
    /** The backgroundColor of the input */
    backgroundColor?: string;
    /** The color of the inner input */
    innerBackgroundColor?: string;
    /** The borderRadius of the input */
    borderRadius?: number;
    /** If input is readOnly */
    readOnly?: boolean;
    /** The padding of the input */
    padding?: string;
    /** The size of the font to display the input in */
    fontSize?: number | string;
    /** The weight of the font of the value and the placeholder */
    fontWeight?: number | string;
    /** The fontsize of the shrunken label */
    shrunkenFontSize?: number | string;
    /** If the input is multiline */
    multiline?: boolean;
    /** Cant edit */
    cantEdit?: boolean;
}

const useTextfieldStyles = (props: StyleProps): Function =>
    makeStyles(() => ({
        root: {
            borderRadius: props.borderRadius,
            backgroundColor: props.backgroundColor,
            '& input, textarea': {
                cursor: props.cantEdit ? 'default' : 'text',
                padding: props.multiline ? '' : props.padding,
                fontWeight: props.fontWeight,
                color: props.textColor,
                fontSize: props.fontSize,
                '&::placeholder': {
                    fontSize: props.fontSize,
                },
                backgroundColor: props.innerBackgroundColor,
                borderRadius: props.multiline ? '0px' : props.borderRadius,
            },
            '& label': {
                marginTop: props.multiline ? '0px' : '-4px',
                fontSize: props.fontSize,
            },
            '& label.ErkC-MuiInputLabel-shrink': {
                fontSize: props.shrunkenFontSize,
                marginTop: '0px',
            },
            '& input + fieldset': {
                borderRadius: props.borderRadius,
            },
            '& .ErkC-MuiInput-underline:after': {
                borderBottomColor: props.outlineColor,
            },
            '& .ErkC-MuiOutlinedInput-root': {
                borderRadius: props.borderRadius,
                '&.Mui-focused fieldset': {
                    borderColor: props.focusColor,
                    borderWidth: props.readOnly ? 1 : 2,
                },
                '& .ErkC-MuiOutlinedInput-notchedOutline': {
                    borderColor: props.outlineColor,
                    '& span': {
                        fontSize: props.shrunkenFontSize,
                        paddingRight: 0,
                        paddingLeft: 0,
                    },
                },
            },
            '& .ErkC-MuiOutlinedInput-root:hover .ErkC-MuiOutlinedInput-notchedOutline':
                {
                    borderColor: props.cantEdit
                        ? props.outlineColor
                        : props.focusColor,
                },
            '& label.Mui-focused': {
                color: props.focusColor,
            },
            '& .Mui-error': {
                color: props.errorColor,
            },
        },
    }));

interface RoundedTextFieldProps extends StyleProps {}
function CustomTextfield({
    focusColor = 'var(--secondary)',
    outlineColor = 'var(--outlineGrey)',
    backgroundColor = 'white',
    innerBackgroundColor,
    textColor = 'var(--accent)',
    readOnly = false,
    borderRadius = 10,
    padding = '6px 12px',
    fontSize = '1rem',
    shrunkenFontSize = '1rem',
    errorColor = 'var(--error)',
    fontWeight = '300',
    multiline = false,
    cantEdit = false,
    ...others
}: TextFieldProps & RoundedTextFieldProps): JSX.Element {
    const classes = useTextfieldStyles({
        padding,
        textColor,
        errorColor,
        focusColor,
        outlineColor,
        innerBackgroundColor: innerBackgroundColor ?? backgroundColor,
        backgroundColor,
        borderRadius,
        readOnly,
        fontSize,
        shrunkenFontSize,
        fontWeight,
        multiline,
        cantEdit,
    })();
    return (
        <TextField
            classes={classes}
            {...others}
            multiline={multiline}
            variant="outlined"
            size="small"
            fullWidth
            InputProps={{
                readOnly,
                disabled: cantEdit,
            }}
        />
    );
}

/**
 * Generic textfield with apps designs.
 */
class RoundedTextField extends React.Component<
    RoundedTextFieldProps & TextFieldProps
> {
    render(): JSX.Element {
        return <CustomTextfield {...this.props} />;
    }
}
export default RoundedTextField;
