import axiosInstance from '../../AxiosAPI';
import { Ticket, Payload, Action } from '../../@Types/@Types';
import ActionTypes from '../../constants/ActionTypes';
import ActivityTypes from '../../constants/ActivityTypes';
import { Activity } from '../../@Types/Activity';

export async function fetchTicket(id: string): Promise<Ticket | void> {
    //TODO: Arreglar esta parte, es en caso de que la lambda este fria y no retorne antes de 30seg
    try {
        const response = await axiosInstance.get(`/ticket/${id}`);
        if (response.data !== '') {
            return {
                ...response.data,
                activities:
                    response.data.process?.current.filter(
                        (activity: Activity) =>
                            activity.type === ActivityTypes.CLIENT_FORM
                    ) ?? [],
                creation_date: new Date(response.data.creation_date),
                actions: [...response.data.actions]?.map?.(
                    (element: Action) => {
                        const action: Action = {
                            ...element,
                            creation_date: new Date(element.creation_date),
                        };
                        switch (action.type) {
                            case ActionTypes.ACTIVITY:
                                switch (action.activityType) {
                                    case ActivityTypes.SCHEDULE:
                                    case ActivityTypes.SCHEDULE_CANCEL:
                                        action.scheduleDate = {
                                            startDate: new Date(
                                                action.scheduleDate.startDate
                                            ),
                                            endDate: new Date(
                                                action.scheduleDate.endDate
                                            ),
                                        };
                                        break;

                                    default:
                                        break;
                                }
                                break;

                            default:
                                break;
                        }
                        return action;
                    }
                ),
            };
        }
    } catch (error) {
        //Handle cold start?
        console.error(error);
    }
}

export async function sendReply(id: string, payload: Payload): Promise<any> {
    return await axiosInstance.post(`/tickets/${id}/reply`, payload);
}

export async function sendRating(id: string, payload: any): Promise<void> {
    await axiosInstance.post(`/tickets/${id}/survey`, payload);
}
export async function sendActivity(
    idTicket: string,
    idActivity: string,
    payload: any
): Promise<void> {
    await axiosInstance.post(
        `/tickets/${idTicket}/activities/${idActivity}`,
        payload
    );
}
