import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { PayloadImage } from '../../@Types/Payload';
import getImageComponent from './ImageComponent';

interface DraftRendererProps {
    editorState: EditorState;
    editorRef?: any;
    images?: Record<string, PayloadImage>;
}

function DraftRenderer({
    images,
    editorRef,
    editorState,
}: DraftRendererProps): JSX.Element {
    function customBlockRender(block: any, config?: any): any {
        if (block.getType() === 'atomic') {
            const contentState = config.getEditorState().getCurrentContent();
            const entityKey = block.getEntityAt(0);
            const entity = contentState.getEntity(entityKey);
            if (entity && entity.type === 'IMAGE') {
                return {
                    component: getImageComponent(),
                    editable: false,
                    props: {
                        images,
                    },
                };
            }
        }
        return undefined;
    }
    return (
        <Editor
            editorRef={(e: any): void => {
                if (editorRef) {
                    editorRef.current = e;
                }
            }}
            editorState={editorState}
            readOnly
            toolbarHidden
            customBlockRenderFunc={customBlockRender}
        />
    );
}

export default DraftRenderer;
