import {
    takeLatest,
    call,
    fork,
    take,
    put,
    takeEvery,
    select,
    cancelled,
    delay,
} from 'redux-saga/effects';
import * as actions from './SocketsActions';
import * as ticketActions from '../TicketController/TicketActions';
import {
    createSocket,
    onMessage,
    removeListener,
    onClose,
} from './SocketsService';
import { AnyAction } from 'redux';
import { EventChannel, eventChannel } from 'redux-saga';
import { RootState } from '../../utils/_store';
import { Ticket } from '../../@Types/Ticket';
import SOCKET_MESSAGE_TYPES from '../../constants/SocketMessageTypes';
import axiosInstance from '../../AxiosAPI';

/**
 * Function to get the current ticket shown in the tickets page
 * @param state the application redux state
 */
const getCurrentTicket = (state: RootState): Ticket | null | undefined => {
    return state.ticketDetail.ticket;
};
/**
 * Function that creates a saga channel, and emit events when the socket receive incoming messages
 * @param socket the socket that is going to emit data in the channel
 */
const createSocketChannel = (socket: WebSocket): EventChannel<any> =>
    eventChannel((emit) => {
        const handler = (data: Record<string, any>): void => {
            emit(data);
        };
        onMessage(socket, handler);
        return (): void => {
            removeListener(socket, handler);
        };
    });
/**
 * Function that creates a "listener" of a close socket event
 * @param socket the socket where the listener is going to be created
 */
const disconnect = (socket: WebSocket): Promise<CloseEvent> => {
    return new Promise((resolve) => {
        onClose(socket, (event: CloseEvent) => {
            resolve(event);
        });
    });
};
/**
 * Function that close all socket connections, when a closed event is received
 * @param socket the socket
 * @param socketChannel the channel where socket events are putted in
 */
const listenDisconnectSocket = function* (
    socket: WebSocket,
    socketChannel: EventChannel<any>
): any {
    yield call(disconnect, socket);
    yield put(
        actions.closeSocket({
            socket: socket,
            socketChannel: socketChannel,
        })
    );
};
/**
 * Function that close all socket connections, when a closed event is received
 * @param socket the socket
 * @param socketChannel the channel where socket events are putted in
 */
const manageSocket = function* (action: AnyAction): any {
    while (true) {
        yield call(
            connectSocket,
            action.payload.idTicket,
            action.payload.idOrganization
        );
        yield delay(5000);
    }
};
/**
 * Function called when a closed action is received (Logout or timeout)
 * @param action of type closeSocket
 */
function* closeConnections(action: AnyAction): any {
    action.payload.socketChannel.close();
    action.payload.socket.close();
}
/**
 * Function called to manage the socket connection (Create the socket, close the socket, create a channel and handle the channel events)
 * @param action of type initiateSocket
 */
function* connectSocket(idTicket: string, idOrganization: string): any {
    let socket;
    let socketChannel;
    try {
        socket = (yield call(
            createSocket,
            idTicket,
            idOrganization
        )) as unknown as WebSocket;
        socketChannel = yield call(createSocketChannel, socket);
        yield fork(listenDisconnectSocket, socket, socketChannel);
        while (true) {
            const payload = yield take(socketChannel);
            yield put(actions.receiveMessage(payload));
        }
    } catch (error) {
        console.error(error);
    } finally {
        if (yield cancelled()) {
            if (socketChannel) {
                socketChannel.close();
            }
            if (socket) {
                socket.close();
            }
        }
    }
}
/**
 * Function called when a message is received by the socket
 * @param action of type receiveMessage
 */
function* manageIncommingMessages(action: AnyAction): any {
    switch (action.payload.type) {
        case SOCKET_MESSAGE_TYPES.TICKET_ACTION:
            const actualTicket = yield select(getCurrentTicket);
            const idTicket = action.payload.idTicket;
            if (actualTicket) {
                if (actualTicket._id === idTicket) {
                    yield put(ticketActions.refresh(false));
                }
            }
            break;
        default:
            if (action.payload.idSocket) {
                axiosInstance.defaults.headers['erk-idsocket'] =
                    action.payload.idSocket;
            }
            break;
    }
}

function* watchInitiateSocket(): any {
    yield takeLatest([actions.Types.INITIATE_SOCKET], manageSocket);
}
function* watchReceiveMessage(): any {
    yield takeEvery([actions.Types.RECEIVE_MESSAGE], manageIncommingMessages);
}
function* watchCloseSocket(): any {
    yield takeEvery([actions.Types.CLOSE_SOCKET], closeConnections);
}
export default [
    fork(watchInitiateSocket),
    fork(watchReceiveMessage),
    fork(watchCloseSocket),
];
