import React from 'react';
import styles from './Navbar.module.css';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { IconButton, withStyles } from '@material-ui/core';
import { RootState } from '../../utils/_store';
import { useSelector } from 'react-redux';
import { Route, RouterProps, Switch, withRouter } from 'react-router-dom';

interface NavbarProps {
    /** If the app is mobile */
    mobile: boolean;
}
function Navbar({ history, mobile }: RouterProps & NavbarProps): JSX.Element {
    const siteInfo = useSelector((state: RootState) => state.site);

    const open = (): void => {
        const url = history.location.pathname + '/info';
        history.push({
            pathname: url,
            search: history.location.search,
            state: { prevPath: history.location.pathname },
        });
    };

    const close = (): void => {
        if (
            (history.location?.state as any)?.prevPath ===
            history.location.pathname.replace('/info', '')
        ) {
            history.goBack();
        } else {
            history.push({
                pathname: history.location.pathname.replace('/info', ''),
                search: history.location.search,
            });
        }
    };

    return (
        <div
            className={styles.navContainer}
            style={{
                backgroundColor:
                    siteInfo.branding?.colors.navbarColor ??
                    siteInfo.branding?.colors.primaryColor,
            }}
        >
            <img
                src={siteInfo.branding?.images.logoUrl}
                alt={siteInfo.branding?.name}
                className={styles.navImage}
            />
            {mobile && (
                <Switch>
                    <Route path="/tickets/:token/info">
                        <div
                            className={styles.toggle}
                            style={{ display: 'none' }}
                        >
                            <RoundedIconButton
                                onClick={(): void => {
                                    close();
                                }}
                            >
                                <NavigateBeforeIcon />
                            </RoundedIconButton>
                        </div>
                    </Route>
                    <Route path="/tickets/:token">
                        <div
                            className={styles.toggle}
                            style={{ display: 'none' }}
                        >
                            <RoundedIconButton
                                onClick={(): void => {
                                    open();
                                }}
                            >
                                <span
                                    style={{
                                        fontSize: 20,
                                        fontFamily: 'Libre Baskerville',
                                    }}
                                >
                                    i
                                </span>
                            </RoundedIconButton>
                        </div>
                    </Route>
                </Switch>
            )}
        </div>
    );
}

export default withRouter((props: any) => <Navbar {...props} />);

interface RoundedIconButtonProps {
    /** Function to call when the button is clicked */
    onClick: Function;
    /** children to display */
    children: any;
}

function RoundedIconButton({
    onClick,
    children,
}: RoundedIconButtonProps): JSX.Element {
    const CustomButton = withStyles({
        root: {
            width: 40,
            margin: 'auto 5px auto auto',
            height: 40,
            borderRadius: 4,
            textTransform: 'none',
            fontFamily: 'Nunito',
            fontSize: 14,
            padding: '4px',
            boxShadow: 'none',
            border: '1px solid white',
            backgroundColor: 'transparent',
            color: 'white',
            '&:hover': {
                boxShadow: 'none',
            },
        },
    })(IconButton);

    return (
        <CustomButton
            onClick={(): void => {
                onClick();
            }}
        >
            {children}
        </CustomButton>
    );
}
