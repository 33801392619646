import { useMediaQuery } from '@material-ui/core';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    setImgMaxWidth,
    setShowRatingConfirmation,
} from '../../../controllers/TicketController/TicketActions';
import Loader from '../../../shared/Loader/Loader';
import { RootState } from '../../../utils/_store';
import Action from '../Action/Action';
import styles from './Activity.module.css';
import Types from '../../../constants/ActionTypes';
import MobileTextEditor from './MobileTextEditor/MobileTextEditor';
import TicketTextEditor from './TicketTextEditor/TicketTextEditor';
import { TicketStateTypes } from '../../../constants/TicketStateTypes';
import RateComponent from './RateComponent/RateComponent';
import RatingConfirmationDialog from './RateComponent/RatingConfirmationDialog/RatingConfirmationDialog';
import React from 'react';
import FormActivityComponent from './Activities/FormActivity/FormActivity';

interface ActivityProps {
    mobile: boolean;
}
function Activity({ mobile }: ActivityProps): JSX.Element {
    const branding = useSelector((state: RootState) => state.site.branding);
    const { ticket, ...ticketInfo } = useSelector(
        (state: RootState) => state.ticketDetail
    );

    const xsm = useMediaQuery('(max-width:576px)', {
        noSsr: true,
    });
    const dispatch = useDispatch();
    const listRef = useRef<any>();

    useEffect(() => {
        /** On load add a resize listener to calc the currentBreakpoint */
        window.addEventListener('resize', handleResize);
        handleResize();
        return (): void => {
            window.removeEventListener('resize', handleResize);
        };
    }, [listRef.current]);

    if (!ticket) return <div></div>;

    /** Calcs the maxwidth of an image */
    const handleResize = (): void => {
        if (listRef.current) {
            dispatch(
                setImgMaxWidth(
                    listRef?.current?.offsetWidth * (mobile ? 0.8 : 0.7)
                )
            );
        }
    };

    const canRate =
        ticket.state.type === TicketStateTypes.CLOSED &&
        !ticket.activities?.length &&
        ticket.rating === undefined &&
        ticket.survey !== undefined;

    const mapElements = (): JSX.Element[] => {
        let elements = [...ticket.actions];
        return elements.map((element, index) => (
            <Action
                ticket={ticket}
                element={element}
                key={index}
                last={index === elements.length - 1 && !canRate}
                xsm={xsm}
            />
        ));
    };

    const renderTextEditor = (): JSX.Element => {
        if (!ticket.canReply || ticket.state.replyDisabled) {
            return <div></div>;
        } else if (mobile) {
            return <MobileTextEditor ticket={ticket} />;
        } else {
            return <TicketTextEditor ticket={ticket} />;
        }
    };

    return (
        <React.Fragment>
            {ticketInfo.showRatingConfirm && (
                <RatingConfirmationDialog
                    onClose={(): void => {
                        dispatch(setShowRatingConfirmation(false));
                    }}
                />
            )}
            <div className={styles.container}>
                <div className={styles.titleContainer}>
                    <div className={styles.overflowContainer}>
                        <label
                            className={styles.lblSubject}
                            title={ticket.subject}
                        >
                            {ticket.subject}
                        </label>
                    </div>
                </div>
                <div
                    className={styles.listContainer}
                    ref={listRef}
                    data-testid="actionslist"
                >
                    {ticketInfo.loading && listRef.current && (
                        <Loader
                            zIndex={1}
                            color={branding?.colors.primaryColor}
                        />
                    )}
                    {!ticketInfo.loading && (
                        <div className={styles.listCont}>
                            <div
                                className={styles.listContCont}
                                data-testid="activity_list_container"
                            >
                                <Action
                                    element={{
                                        type: Types.CLIENT_REPLY,
                                        _id: 'CONTENT',
                                        creation_date: ticket.creation_date,
                                        idProject: ticket.idProject,
                                        payload: ticket.original,
                                    }}
                                    key={0}
                                    last={
                                        ticket.actions.length === 0 && !canRate
                                    }
                                    xsm={xsm}
                                    ticket={ticket}
                                />
                                {mapElements()}

                                {ticket.activities &&
                                    ticket.activities.map((activity, index) => (
                                        <FormActivityComponent
                                            ticket={ticket}
                                            activity={activity}
                                            key={index}
                                        />
                                    ))}
                                {canRate && (
                                    <RateComponent survey={ticket.survey} />
                                )}
                            </div>
                        </div>
                    )}
                </div>
                {renderTextEditor()}
            </div>
        </React.Fragment>
    );
}

export default Activity;
