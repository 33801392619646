import { useDispatch } from 'react-redux';
import RoundedButton from '../../../../shared/RoundedButton/RoundedButton';
import { setShowPostview } from '../../../../controllers/TicketController/TicketActions';
import styles from './FormBtnAction.module.css';
import { Branding } from '../../../../@Types/Branding';
interface FormBtnActionProps {
    branding: Branding;
}

function FormBtnAction({ branding }: FormBtnActionProps): JSX.Element {
    const dispatch = useDispatch();
    return (
        <div className={styles.formBtnContainer}>
            <RoundedButton
                onClick={(): void => {
                    dispatch(setShowPostview(true));
                }}
                backgroundColor={branding.colors.contrastColor}
                text="Ver Formulario"
            />
        </div>
    );
}
export default FormBtnAction;
