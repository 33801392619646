import React, { Component, useState } from 'react';
import classNames from 'classnames';
import { Dialog } from '@material-ui/core';
import { RootState } from '../../utils/_store';
import { useSelector } from 'react-redux';

function ImageComponent({ block, contentState, blockProps }: any): JSX.Element {
    const entityKey = block.getEntityAt(0);
    const entity = contentState.getEntity(entityKey);
    const [showPopUp, setShowPopUp] = useState(false);
    const { alignment, width, alt, imageKey, ratio } = entity.getData();
    const maxWidth = useSelector(
        (state: RootState) => state.ticketDetail.imgMaxWidth
    );
    const image = blockProps?.images?.[imageKey];
    if (!image) {
        return <div></div>;
    }
    const calcHeight = (): number => {
        if (!isNaN(maxWidth) && maxWidth > 0 && maxWidth < width) {
            return maxWidth / ratio + 7;
        } else {
            const newHeight = width / ratio + 7;
            return !isNaN(newHeight) ? newHeight : 0;
        }
    };

    return (
        <span
            className={classNames('rdw-image-alignment', {
                'rdw-image-left': alignment === 'left',
                'rdw-image-right': alignment === 'right',
                'rdw-image-center': !alignment || alignment === 'none',
            })}
        >
            <Dialog
                open={showPopUp}
                onClose={(): void => {
                    setShowPopUp(false);
                }}
                maxWidth={false}
                PaperProps={{
                    style: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    },
                }}
            >
                <img
                    src={image.url}
                    alt={alt}
                    style={{
                        height: 'auto',
                        width: 'auto',
                        maxWidth: '90vw',
                        maxHeight: '90vh',
                    }}
                    onClick={(): void => {
                        setShowPopUp(false);
                    }}
                />
            </Dialog>
            {/* TODO: SOLO mostrar la imagen si esta cerca de ser vista. */}
            <span
                className="rdw-image-imagewrapper"
                onClick={(): void => {
                    setShowPopUp(true);
                }}
                style={{ minHeight: calcHeight() }}
            >
                <img
                    src={image.url}
                    alt={alt}
                    style={{
                        height: 'auto',
                        width,
                        maxWidth: '100%',
                    }}
                />
            </span>
        </span>
    );
}

const getImageComponent = (): any =>
    class Image extends Component {
        render(): Object {
            return <ImageComponent {...this.props} />;
        }
    };

export default getImageComponent;
