import { Payload } from '../../../../@Types/Payload';
import styles from './Payload.module.css';
import { EditorState, convertFromRaw } from 'draft-js';
import FileComponent from '../../../../shared/FileComponent/FileComponent';
import { getRawText } from '../../../../utils/draftFunctions';
import DraftRenderer from '../../../../shared/DraftRenderer/DraftRenderer';
interface PayloadProps {
    content: Payload;
    color: string;
}
function PayloadComponent({
    content,
    color = 'var(--secondary)',
}: PayloadProps): JSX.Element {
    const editorState = EditorState.createWithContent(
        convertFromRaw(getRawText(content.draft, content.text))
    );
    const hasText = editorState.getCurrentContent().hasText();
    return (
        <div className={styles.container}>
            {hasText && (
                <div
                    className={styles.textContainer}
                    data-testid="draft_renderer_container"
                >
                    <DraftRenderer
                        editorState={editorState}
                        images={content.images}
                    />
                </div>
            )}

            {content?.files && content.files.length > 0 && (
                <div
                    className={styles.filesContainer + ' flexbox'}
                    data-testid="payload_files_container"
                    style={
                        hasText
                            ? {
                                  borderTop: '1px solid var(--light-grey)',
                              }
                            : {}
                    }
                >
                    {content.files.map((file, index) => (
                        <div className={styles.fileContainer} key={index}>
                            <FileComponent
                                file={file}
                                marginLeft="0px"
                                showToolTip
                                color={color}
                            />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default PayloadComponent;
