import { takeLatest, fork, call, put } from 'redux-saga/effects';
import * as actions from './SiteActions';
import { AnyAction } from 'redux';
// import LogRocket from 'logrocket';
import { fetchOrg } from './SiteService';

/**
 * Function called when the load action is triggered
 * @param action of the loadTokens
 */
function* load(action: AnyAction): any {
    if (actions.load.match(action)) {
        try {
            const organization = yield call(fetchOrg);
            const favicon: any = document.getElementById('favicon');
            if (favicon) {
                favicon.href = organization.partialLogoUrl;
            }

            // if (process.env.NODE_ENV === 'production') {
            //     LogRocket.init('63mg8a/clientes');
            // }

            yield put(actions.loadSuccess(organization));
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            // if (process.env.NODE_ENV === 'production') {
            //     LogRocket.init('63mg8a/clientes');
            // }
            yield put(actions.loadError());
        }
    }
}

function* watchLoad(): any {
    yield takeLatest([actions.Types.LOAD], load);
}

export default [fork(watchLoad)];
