import React, { Component, useState } from 'react';
import { EditorState } from 'draft-js';
import classNames from 'classnames';
import { RootState } from '../../../utils/_store';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, ClickAwayListener } from '@material-ui/core';
import VerticalAlignBottomRoundedIcon from '@material-ui/icons/VerticalAlignBottomRounded';
import VerticalAlignCenterRoundedIcon from '@material-ui/icons/VerticalAlignCenterRounded';
import VerticalAlignTopRoundedIcon from '@material-ui/icons/VerticalAlignTopRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import styles from './ImageComponent.module.css';
import { editorChange } from '../../../controllers/TextEditorController/TextEditorActions';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
function ImageComponent({
    block,
    contentState,
    config: { onChange, getEditorState },
}: any): JSX.Element {
    const [hovering, setHovering] = useState(false);
    const [active, setActive] = useState(false);
    const dispatch = useDispatch();
    const entityKey = block.getEntityAt(0);
    const entity = contentState.getEntity(entityKey);
    const { alignment, width, alt, imageKey } = entity.getData();
    const editorInfo = useSelector((state: RootState) => state.textEditor);
    const image = editorInfo.images?.[imageKey];

    if (!image) {
        return <div></div>;
    }

    function deleteBlock(): void {
        const selection = getEditorState().getSelection();
        const blockMap = contentState.getBlockMap().delete(block.getKey());
        const withoutAtomic = contentState.merge({
            blockMap,
            selectionAfter: selection,
        });
        dispatch(
            editorChange(
                EditorState.push(
                    getEditorState(),
                    withoutAtomic,
                    'remove-range'
                )
            )
        );
    }

    const loading = image.state === 'FETCHING' || image.state === 'UPLOADING';
    const error = image.state === 'ERROR';
    const setEntityAlignment: Function = (alignment: any): void => {
        const entityKey = block.getEntityAt(0);
        contentState.mergeEntityData(entityKey, { alignment });
        onChange(
            EditorState.push(
                getEditorState(),
                contentState,
                'change-block-data'
            )
        );
    };

    const renderAlignmentOptions = (alignment: any): Object => {
        return (
            <div className={'rdw-image-alignment-options-popup'}>
                <div
                    onClick={(): void => {
                        setEntityAlignment('left');
                    }}
                    className={
                        (alignment === 'left'
                            ? styles.selectedBtn
                            : styles.btn) + ' rdw-image-alignment-option'
                    }
                >
                    <VerticalAlignBottomRoundedIcon fontSize="inherit" />
                </div>
                <div
                    onClick={(): void => {
                        setEntityAlignment('none');
                    }}
                    className={
                        (alignment === 'none' || alignment === undefined
                            ? styles.selectedBtn
                            : styles.btn) + ' rdw-image-alignment-option'
                    }
                >
                    <VerticalAlignCenterRoundedIcon fontSize="inherit" />
                </div>
                <div
                    onClick={(): void => {
                        setEntityAlignment('right');
                    }}
                    className={
                        (alignment === 'right'
                            ? styles.selectedBtn
                            : styles.btn) + ' rdw-image-alignment-option'
                    }
                >
                    <VerticalAlignTopRoundedIcon fontSize="inherit" />
                </div>
                <div
                    onClick={(): void => {
                        deleteBlock();
                    }}
                    className="rdw-image-alignment-option"
                    style={{ color: 'var(--editorFocusColor)' }}
                >
                    <DeleteRoundedIcon fontSize="inherit" />
                </div>
            </div>
        );
    };

    return (
        <span
            className={
                classNames('rdw-image-alignment-editor', {
                    'rdw-image-left': alignment === 'left',
                    'rdw-image-right': alignment === 'right',
                    'rdw-image-center': !alignment || alignment === 'none',
                }) + ' noselect'
            }
        >
            <ClickAwayListener
                mouseEvent="onMouseDown"
                onClickAway={(): void => {
                    setActive(false);
                }}
            >
                <span
                    className="rdw-image-imagewrapper noselect"
                    onMouseEnter={(): void => {
                        if (editorInfo.focus) {
                            setHovering(true);
                        }
                    }}
                    onMouseLeave={(): void => {
                        setHovering(false);
                    }}
                    onClick={(): void => {
                        setActive(true);
                    }}
                    style={{ cursor: editorInfo.focus ? 'pointer' : 'default' }}
                >
                    {loading && (
                        <div className={styles.loaderContainer}>
                            <div className={styles.loader}>
                                <CircularProgress
                                    size={40}
                                    style={{
                                        color: 'var(--editorFocusColor)',
                                    }}
                                />
                            </div>
                        </div>
                    )}

                    {error && (
                        <div className={styles.errorContainer}>
                            <div
                                className={styles.warningIcon}
                                style={{ fontSize: 30 }}
                            >
                                <WarningRoundedIcon fontSize="inherit" />
                            </div>
                            <label
                                className={styles.warningLbl}
                                style={{ fontSize: 15 }}
                            >
                                Error al subir esta imagen
                            </label>
                        </div>
                    )}

                    <img
                        src={image.localSrc}
                        alt={alt}
                        style={{
                            height: 'auto',
                            width,
                            maxWidth: '100%',
                            opacity: loading || error ? 0.4 : 1,
                        }}
                    />
                    <div
                        className={
                            active
                                ? styles.imgWrapperActive
                                : hovering
                                ? styles.imgWrapperHovering
                                : styles.imgWrapper
                        }
                        style={error ? { borderColor: 'var(--error)' } : {}}
                    ></div>
                    {active && renderAlignmentOptions(alignment)}
                </span>
            </ClickAwayListener>
        </span>
    );
}

const getImageComponent = (config: any): any =>
    class Image extends Component {
        render(): Object {
            return <ImageComponent config={config} {...this.props} />;
        }
    };

export default getImageComponent;
