import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Ticket, Payload } from '../../../../@Types/@Types';
import {
    refresh,
    setLoading,
} from '../../../../controllers/TicketController/TicketActions';
import { RootState } from '../../../../utils/_store';

import styles from './TicketTextEditor.module.css';

import ControlledTextEditor from '../../../../shared/TextEditor/ControlledTextEditor';
import RoundedButton from '../../../../shared/RoundedButton/RoundedButton';
import {
    filesError,
    reset,
} from '../../../../controllers/TextEditorController/TextEditorActions';
import { sendReply } from '../../../../controllers/TicketController/TicketService';
import Dialog from '../../../../shared/Dialog/Dialog';
import PayloadConfirmation from '../PayloadConfirmation/PayloadConfirmation';
import { convertToRaw } from 'draft-js';
import { draftRawTrim, getValidImages } from '../../../../utils/draftFunctions';
import { PayloadImage } from '../../../../@Types/Payload';
import { ImageObject } from '../../../../controllers/TextEditorController/TextEditorReducer';

interface TicketEditorProps {
    ticket: Ticket;
}

function TicketEditor({ ticket }: TicketEditorProps): JSX.Element {
    const branding = useSelector((state: RootState) => state.site.branding!);
    const ticketInfo = useSelector((state: RootState) => state.ticketDetail);
    const editorInfo = useSelector((state: RootState) => state.textEditor);
    const [showConfirmation, setShowConfirmation] = useState<any>(undefined);
    const dispatch = useDispatch();
    const handleSend = async (): Promise<void> => {
        if (!ticketInfo.loading) {
            const currentContent = editorInfo.editorState.getCurrentContent();
            //Check if files are valid
            if (
                editorInfo.files.find((file: any) => !file.S3Key) !== undefined
            ) {
                console.error('Files ERRORS:', editorInfo);
                dispatch(filesError());
            } else if (
                //If draft has text or has multiple files
                currentContent.hasText() ||
                editorInfo.files.length > 0
            ) {
                try {
                    const validImages = getValidImages(
                        currentContent,
                        editorInfo.images
                    );
                    let draft = convertToRaw(currentContent);
                    if (Object.keys(validImages).length > 0) {
                        draft = draftRawTrim(draft);
                    }
                    //Mapear de images a obj.
                    const payload: Payload = {
                        text: currentContent.getPlainText(),
                        draft,
                        files: editorInfo.files.map((val: any) => ({
                            fileName: val.fileName,
                            S3Key: val.S3Key,
                        })),
                        images: validImages,
                    };
                    setShowConfirmation(payload);
                } catch (error) {
                    console.error(error);
                    dispatch(setLoading(false));
                }
            }
        }
    };
    return (
        <div className={styles.textEditorContainer}>
            {showConfirmation !== undefined && (
                <Dialog
                    open={showConfirmation !== undefined}
                    disableEnforceFocus
                    onClose={(): void => setShowConfirmation(undefined)}
                >
                    <PayloadConfirmation
                        close={(): void => setShowConfirmation(undefined)}
                        payload={showConfirmation}
                        images={mapImages(editorInfo.images)}
                        handleConfirm={async (): Promise<void> => {
                            await sendReply(ticket._id, showConfirmation);
                            dispatch(refresh());
                            dispatch(reset());
                        }}
                    />
                </Dialog>
            )}
            <ControlledTextEditor
                placeholder={'Enviar un mensaje...'}
                focusDelay={500}
                cantEdit={ticketInfo.loading}
            />
            <div
                className={styles.divButton}
                data-testid="ticket_editor_submit"
            >
                <RoundedButton
                    text={'Enviar'}
                    backgroundColor={branding.colors.contrastColor}
                    color="white"
                    disabled={ticketInfo.loading}
                    bold={true}
                    onClick={(): void => {
                        handleSend();
                    }}
                />
            </div>
        </div>
    );
}
export default TicketEditor;

/**
 * Function that maps ImageObjects to Payload images to display in the preview
 */
function mapImages(
    images: Record<string, ImageObject>
): Record<string, PayloadImage> {
    const response: Record<string, PayloadImage> = {};
    for (const imgKey of Object.keys(images)) {
        response[imgKey] = {
            url: images[imgKey].localSrc,
            S3Key: images[imgKey].S3Key ?? '',
            imageKey: images[imgKey].imageKey,
        };
    }
    return response;
}
