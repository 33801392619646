import React, { useEffect, useState } from 'react';

import styles from './ProcessElement.module.css';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { format } from 'date-fns';
import ScheduleRoundedIcon from '@material-ui/icons/ScheduleRounded';
import { getLocale } from '../../..';
import { ProcessVisualElement } from '../../../@Types/Ticket';
import { RootState } from '../../../utils/_store';
import { useSelector } from 'react-redux';

interface ProcessElementProps {
    element: ProcessVisualElement;
    last: boolean;
}

function ProcessElement({ element, last }: ProcessElementProps): JSX.Element {
    const branding = useSelector((state: RootState) => state.site.branding);
    const [open, setOpen] = useState(
        element.type === 'DEFAULT' &&
            element.progress !== null &&
            element.progress > 0 &&
            element.progress < 100 &&
            element.subElements.length > 0
    );

    useEffect(() => {
        setOpen(
            element.type === 'DEFAULT' &&
                element.progress !== null &&
                element.progress > 0 &&
                element.progress < 100 &&
                element.subElements.length > 0
        );
    }, [element]);

    const hasSubElements =
        element.type === 'DEFAULT' &&
        element.subElements.length > 0 &&
        (element.progress === 0 || element.progress === 100);
    const renderIcon = (): JSX.Element => {
        if (element.type === 'CANCELLED') {
            return (
                <div className={styles.errorIcon}>
                    <WarningRoundedIcon
                        fontSize="inherit"
                        style={{ marginTop: 1, marginLeft: 2 }}
                    />
                </div>
            );
        } else if (element.type === 'DATE') {
            const endDate = new Date(element.endDate).getTime();
            const startDate = new Date(element.startDate).getTime();
            const today = new Date().getTime();
            const q = Math.abs(today - startDate);
            const d = Math.abs(endDate - startDate);
            let progress = Math.round((q / d) * 100);
            if (progress === 0 && today > startDate) {
                progress = 1;
            } else if (progress > 100) {
                progress = 99;
            }
            return (
                <div
                    className={styles.partialIcon}
                    style={
                        {
                            '--p': progress + '%',
                            border: `2px solid ${branding?.colors.primaryColor}`,
                        } as any
                    }
                ></div>
            );
        } else if (element.progress === 100) {
            return (
                <div
                    className={styles.fullIcon}
                    style={{
                        backgroundColor: branding?.colors.primaryColor,
                    }}
                >
                    <CheckRoundedIcon
                        fontSize="inherit"
                        style={{ marginTop: 2, marginLeft: 2 }}
                    />
                </div>
            );
        } else if (element.progress > 0) {
            let progress = element.progress;
            if (progress > 90) progress = 90;
            if (progress < 10) progress = 10;
            return (
                <div
                    className={styles.partialIcon}
                    style={
                        {
                            '--p': progress + '%',
                            '--secondary': branding?.colors.primaryColor,
                            border: `2px solid ${branding?.colors.primaryColor}`,
                        } as any
                    }
                ></div>
            );
        } else {
            return <div className={styles.emptyIcon}></div>;
        }
    };

    const renderContent = (): JSX.Element | void => {
        if (element.type === 'DATE') {
            return (
                <React.Fragment>
                    <div className={styles.dateInfo}>
                        <ScheduleRoundedIcon
                            fontSize="inherit"
                            style={{ color: 'var(--secondary)' }}
                        />
                        <label className={styles.dateLbl}>
                            {format(new Date(element.endDate), 'Pp', {
                                locale: getLocale(),
                            })}
                        </label>
                    </div>
                </React.Fragment>
            );
        }
    };
    return (
        <React.Fragment>
            <div
                className={styles.header}
                style={{
                    cursor: hasSubElements ? 'pointer' : 'default',
                }}
                onClick={(): void => {
                    if (hasSubElements) {
                        setOpen(!open);
                    }
                }}
            >
                <div className={styles.iconContainer}>{renderIcon()}</div>
                <label
                    className={styles.label}
                    style={{
                        cursor: hasSubElements ? 'pointer' : 'default',
                    }}
                >
                    {element.label + (open ? ':' : '')}
                </label>
                {hasSubElements && (
                    <div className={styles.showMoreIcon}>
                        {open ? (
                            <ExpandLessIcon fontSize="inherit" />
                        ) : (
                            <ExpandMoreIcon fontSize="inherit" />
                        )}
                    </div>
                )}
            </div>
            {open && element.type === 'DEFAULT' && (
                <div className={styles.subElementsContainer}>
                    {element.subElements.map((e, index) => (
                        <ProcessElement
                            key={index}
                            element={e}
                            last={index === element.subElements.length - 1}
                        />
                    ))}
                </div>
            )}
            {!open && !last && (
                <div className={styles.infoContainer}>
                    <div className={styles.separatorCont}>
                        <div
                            className={styles.separator}
                            style={{
                                backgroundColor:
                                    element.type === 'DEFAULT' &&
                                    element.progress === 100
                                        ? 'var(--secondary)'
                                        : '#bdbdbd',
                            }}
                        ></div>
                    </div>
                    <div className={styles.info}>{renderContent()}</div>
                </div>
            )}
            {!open &&
                last &&
                element.type === 'DEFAULT' &&
                element.progress > 0 &&
                element.progress < 100 && (
                    <div className={styles.lastInfo}>{renderContent()}</div>
                )}
        </React.Fragment>
    );
}
export default ProcessElement;
