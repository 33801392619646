import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './utils/reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import store from './utils/_store';
import './styles/index.css';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';

export const localeMap: any = {
    en: enLocale,
    'en-US': enLocale,
    es: esLocale,
};

export const getLocale = (): any => {
    return (
        // localeMap[
        //     navigator.languages && navigator.languages.length
        //         ? navigator.languages[0]
        //         : (navigator as any).userLanguage ||
        //           navigator.language ||
        //           (navigator as any).browserLanguage
        // ] ?? localeMap.es
        localeMap.es
    );
};

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <App />
        </Router>
        ,
    </Provider>,
    document.getElementById('root')
);

// eslint-disable-next-line no-console
reportWebVitals(console.log); // TODO: Learn more: https://bit.ly/CRA-vitals
