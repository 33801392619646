import styles from '../Rating.module.css';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import { RatingProps } from '../Rating';

function SatisfactionRating({
    value,
    inputRef,
    disabled,
    onChange,
    isAction,
    focusColor,
    unSelectedColor,
}: RatingProps): JSX.Element {
    return (
        <div
            className={styles.ratingContainer}
            style={
                {
                    '--eureka-primary': focusColor,
                    '--eureka-outline': unSelectedColor,
                } as React.CSSProperties
            }
        >
            <input ref={inputRef} className={styles.hiddenInput} />
            <div
                className={
                    disabled
                        ? styles.disabledSentimentContainer
                        : styles.sentimentContainer
                }
                style={{ fontSize: isAction ? 40 : 60 }}
                onClick={(): void => {
                    if (!disabled) onChange(value === 1 ? null : 1);
                }}
            >
                <SentimentVeryDissatisfiedIcon
                    fontSize="inherit"
                    style={
                        value === 1
                            ? {
                                  color: focusColor,
                              }
                            : {}
                    }
                />
            </div>
            <div
                className={
                    disabled
                        ? styles.disabledSentimentContainer
                        : styles.sentimentContainer
                }
                style={{ fontSize: isAction ? 40 : 60 }}
                onClick={(): void => {
                    if (!disabled) onChange(value === 2 ? null : 2);
                }}
            >
                <SentimentDissatisfiedIcon
                    fontSize="inherit"
                    style={
                        value === 2
                            ? {
                                  color: focusColor,
                              }
                            : {}
                    }
                />
            </div>
            <div
                className={
                    disabled
                        ? styles.disabledSentimentContainer
                        : styles.sentimentContainer
                }
                style={{ fontSize: isAction ? 40 : 60 }}
                onClick={(): void => {
                    if (!disabled) onChange(value === 3 ? null : 3);
                }}
            >
                <SentimentSatisfiedAltIcon
                    fontSize="inherit"
                    style={
                        value === 3
                            ? {
                                  color: focusColor,
                              }
                            : {}
                    }
                />
            </div>
            <div
                className={
                    disabled
                        ? styles.disabledSentimentContainer
                        : styles.sentimentContainer
                }
                style={{ fontSize: isAction ? 40 : 60 }}
                onClick={(): void => {
                    if (!disabled) onChange(value === 4 ? null : 4);
                }}
            >
                <SentimentVerySatisfiedIcon
                    fontSize="inherit"
                    style={
                        value === 4
                            ? {
                                  color: focusColor,
                              }
                            : {}
                    }
                />
            </div>
        </div>
    );
}

export default SatisfactionRating;
