import styles from './MobileTextEditor.module.css';
import MobileTextField from './MobileTextField';
import { Fab, makeStyles } from '@material-ui/core';
import {
    calcImageEntityData,
    getRawText,
    stringToDraft,
} from '../../../../utils/draftFunctions';
import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Payload, Ticket } from '../../../../@Types/@Types';
import { reset } from '../../../../controllers/TextEditorController/TextEditorActions';
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import { RootState } from '../../../../utils/_store';
import Dialog from '../../../../shared/Dialog/Dialog';
import PayloadConfirmation from '../PayloadConfirmation/PayloadConfirmation';
import { AtomicBlockUtils, convertToRaw, EditorState } from 'draft-js';
import {
    getUploadUrls,
    postFile,
} from '../../../../controllers/FilesController/FilesService';
import maxSize from '../../../../constants/Files/MaxSize';
import {
    refresh,
    setLoading,
} from '../../../../controllers/TicketController/TicketActions';
import { sendReply } from '../../../../controllers/TicketController/TicketService';
interface StyleProps {
    /** The backgroundColor of the input */
    backgroundColor?: string;
}
const useStyles = (props: StyleProps): Function =>
    makeStyles(() => ({
        root: {
            marginLeft: 4,
            paddingLeft: 2,
            backgroundColor: props.backgroundColor,
            color: 'var(--primary1)',
            '&:hover': {
                backgroundColor: props.backgroundColor,
            },
            '&.Mui-disabled': {
                backgroundColor: props.backgroundColor,
                color: 'var(--primary1)',
            },
        },
    }));

interface MobileTextEditorProps {
    ticket: Ticket;
}
function MobileTextEditor({ ticket }: MobileTextEditorProps): JSX.Element {
    const branding = useSelector((state: RootState) => state.site.branding!);
    const btnClasses = useStyles({
        backgroundColor: branding.colors.contrastColor,
    })();
    const [showConfirmation, setShowConfirmation] = useState<any>(undefined);
    const fieldRef = useRef<any>();
    const dispatch = useDispatch();
    const ticketInfo = useSelector((state: RootState) => state.ticketDetail);
    const [text, setText] = useState('');

    /** Deletes Info on ticket change */
    useEffect(() => {
        dispatch(reset());
    }, [ticket]);

    const onImageSelected = async (file: File): Promise<void> => {
        const localSrc = URL.createObjectURL(file);
        const entityData = await calcImageEntityData(localSrc);
        const editorState = EditorState.createEmpty();
        const entityKey = editorState
            .getCurrentContent()
            .createEntity('IMAGE', 'MUTABLE', entityData)
            .getLastCreatedEntityKey();
        const newEditorState = AtomicBlockUtils.insertAtomicBlock(
            editorState,
            entityKey,
            ' '
        );
        const currentContent = newEditorState.getCurrentContent();

        setShowConfirmation({
            text: '',
            draft: convertToRaw(currentContent),
            images: {
                [entityData.imageKey]: {
                    url: localSrc,
                    file,
                    imageKey: entityData.imageKey,
                    state: 'FETCHING',
                },
            },
        });
    };

    const handleSend = async (): Promise<void> => {
        if (!ticketInfo.loading) {
            if (text.trim().length > 0) {
                try {
                    let draft = stringToDraft(text);
                    const payload: Payload = {
                        text,
                        draft,
                    };
                    setShowConfirmation(payload);
                } catch (error) {
                    console.error(error);
                }
            }
        }
    };

    const handleConfirm = async (): Promise<void> => {
        const payload = { ...showConfirmation };
        dispatch(setLoading());
        //Upload the files
        const file = payload?.files?.[0]?.file;
        const image: any = Object.values(payload?.images ?? {})?.[0];
        if (file) {
            try {
                const fileLinks = await getUploadUrls([file]);
                if (file.size <= maxSize) {
                    const uploadedFile = await postFile(
                        file,
                        fileLinks[0] as any
                    );
                    payload.draft = getRawText();
                    payload.text = '';
                    payload.files = [uploadedFile];
                }
            } catch (error) {
                console.error('UPLOADFILEERROR', error);
            }
        } else if (image) {
            try {
                const fileLinks = await getUploadUrls([image.file]);
                if (image.file.size <= maxSize) {
                    const uploadedImage = await postFile(
                        image.file,
                        fileLinks[0] as any
                    );
                    payload.text = '';
                    payload.images = {
                        [image.imageKey]: {
                            S3Key: uploadedImage.S3Key,
                            imageKey: image.imageKey,
                        },
                    };
                }
            } catch (error) {
                console.error('UPLOADIMAGEERROR', error);
                delete payload.images;
            }
        } else {
            delete payload.files;
            delete payload.images;
        }
        try {
            await sendReply(ticket._id, payload);
            dispatch(refresh());
            setShowConfirmation(undefined);
            setText('');
        } catch (error) {
            console.error(error);
            dispatch(setLoading(false));
        }
    };

    return (
        <React.Fragment>
            {showConfirmation !== undefined && (
                <Dialog
                    open={showConfirmation !== undefined}
                    disableEnforceFocus
                    onClose={(): void => setShowConfirmation(undefined)}
                >
                    <PayloadConfirmation
                        close={(): void => setShowConfirmation(undefined)}
                        payload={showConfirmation}
                        images={showConfirmation.images ?? {}}
                        handleConfirm={handleConfirm}
                    />
                </Dialog>
            )}
            <div className={styles.textFieldContainer}>
                <div className={styles.textField}>
                    <MobileTextField
                        inputRef={fieldRef}
                        value={text}
                        onChange={(e): void => {
                            setText(e.target.value);
                        }}
                        onImageSelected={onImageSelected}
                        onFileSelected={(file: File): void => {
                            console.error(file);
                            setShowConfirmation({
                                files: [{ file }],
                            });
                        }}
                        placeholder={'Enviar un mensaje...'}
                        cantEdit={ticketInfo.loading}
                    />
                </div>
                <Fab
                    size="small"
                    className={btnClasses.root}
                    onClick={(): void => {
                        handleSend();
                    }}
                    disabled={ticketInfo.loading}
                >
                    <SendRoundedIcon />
                </Fab>
            </div>
        </React.Fragment>
    );
}

export default MobileTextEditor;
