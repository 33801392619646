import ThumbDownRoundedIcon from '@material-ui/icons/ThumbDownRounded';
import ThumbUpRoundedIcon from '@material-ui/icons/ThumbUpRounded';
import { RatingProps } from '../Rating';
import styles from '../Rating.module.css';

function LikeRating({
    value,
    onChange,
    disabled,
    inputRef,
    isAction,
    focusColor,
    unSelectedColor,
}: RatingProps): JSX.Element {
    return (
        <div
            className={styles.ratingContainer}
            style={
                {
                    '--eureka-primary': focusColor,
                    '--eureka-outline': unSelectedColor,
                } as React.CSSProperties
            }
        >
            <input ref={inputRef} className={styles.hiddenInput} />
            <div
                className={
                    disabled
                        ? styles.disabledLikeContainer
                        : styles.likeContainer
                }
                style={{ fontSize: isAction ? 40 : 60 }}
                onClick={(): void => {
                    if (!disabled) onChange(value === 1 ? null : 1);
                }}
            >
                <ThumbUpRoundedIcon
                    fontSize="inherit"
                    style={
                        value === 1
                            ? {
                                  color: focusColor,
                              }
                            : {}
                    }
                />
            </div>
            <div
                className={
                    disabled
                        ? styles.disabledLikeContainer
                        : styles.likeContainer
                }
                style={{ fontSize: isAction ? 40 : 60 }}
                onClick={(): void => {
                    if (!disabled) onChange(value === 0 ? null : 0);
                }}
            >
                <ThumbDownRoundedIcon
                    fontSize="inherit"
                    style={
                        value === 0
                            ? {
                                  color: focusColor,
                                  marginTop: 14,
                                  marginLeft: 15,
                              }
                            : { marginTop: 14, marginLeft: 15 }
                    }
                />
            </div>
        </div>
    );
}

export default LikeRating;
