import { useRef, useState } from 'react';
import styles from './Attachments.module.css';
import AttachFileRoundedIcon from '@material-ui/icons/AttachFileRounded';
import { getAcceptedExtensions } from '../../../constants/Files/extensions';
import React from 'react';
import { CircularProgress } from '@material-ui/core';
import FileUploadMenu from './FileUploadMenu/FileUploadMenu';
import {
    FileObject,
    UploadedFileObject,
} from '../../../controllers/TextEditorController/TextEditorReducer';
import { Branding } from '../../../@Types/Branding';

interface AttachmentsProps {
    files: (FileObject | UploadedFileObject)[];
    handleUpload: (files: File[]) => void;
    handleRemove: (file: FileObject) => void;
    hasError: boolean;
    branding: Branding;
}
/**
 * Generic attach file button for text editor
 */
function Attachments({
    files,
    hasError,
    handleUpload,
    handleRemove,
    branding,
}: AttachmentsProps): JSX.Element {
    const [showMenu, setShowMenu] = useState(false);

    const inputRef = useRef<any>();
    const btnRef = useRef<any>();

    const onButtonClick = (): void => {
        if (inputRef.current) {
            if (files.length > 0) {
                setShowMenu(true);
            } else {
                inputRef.current.click();
            }
        }
    };

    const onFilesSelected = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const newFiles = e.target.files;
        if (newFiles) {
            handleUpload(Array.from(newFiles));
            setShowMenu(true);

            if (inputRef.current) {
                inputRef.current.value = '';
            }
        }
    };

    const calcErrors = (): string => {
        const errs = files.filter((file: any) => !file.S3Key);
        const uploadFiles = errs.filter((file) =>
            ['UPLOADING', 'FETCHING'].includes((file as FileObject).state)
        );
        if (uploadFiles.length > 0) {
            return 'Algunos archivos no han terminado de subirse!';
        }
        return 'Hay archivos con errores!';
    };

    return (
        <div
            onClick={onButtonClick}
            className={styles.btnContainer}
            data-testid="upload_file"
        >
            <button className={styles.attachButton} ref={btnRef}>
                <AttachFileRoundedIcon fontSize="inherit" />
                <div className={styles.countContainer}>
                    {files && files.length > 0 && (
                        <div className={styles.countLbl}>{files.length}</div>
                    )}
                    {!showMenu &&
                        files &&
                        files.filter(
                            (file) =>
                                (file as FileObject).state === 'UPLOADING' ||
                                (file as FileObject).state === 'FETCHING'
                        ).length > 0 && (
                            <CircularProgress
                                size={18}
                                style={{
                                    color: branding.colors.secondaryColor,
                                    position: 'absolute',
                                }}
                            />
                        )}
                </div>
            </button>
            {showMenu && files && files.length > 0 && (
                <FileUploadMenu
                    branding={branding}
                    files={files}
                    anchorRef={btnRef}
                    hasError={hasError}
                    handleAddFiles={(): void => {
                        inputRef.current.click();
                    }}
                    handleClose={(): void => {
                        setShowMenu(false);
                    }}
                    handleRemove={(
                        fileToRemove: FileObject | UploadedFileObject
                    ): void => {
                        if ((fileToRemove as FileObject).state !== undefined) {
                            if (files.length === 1) {
                                handleRemove(fileToRemove as FileObject);
                            } else {
                                handleRemove(fileToRemove as FileObject);
                            }
                        }
                    }}
                />
            )}
            {(!showMenu || (showMenu && files.length === 0)) && hasError && (
                <React.Fragment>
                    <div className={styles.errorPopper}>
                        <label className={styles.errorPopperLbl}>
                            {calcErrors()}
                        </label>
                    </div>
                    <div className={styles.errorArrow}></div>
                </React.Fragment>
            )}
            <input
                type="file"
                ref={inputRef}
                style={{ display: 'none' }}
                onChange={onFilesSelected}
                multiple
                accept={getAcceptedExtensions()}
            />
        </div>
    );
}
export default Attachments;
