import React from 'react';
import styles from './SurveyDialog.module.css';
import EurekaForm from '../../../../../shared/EurekaForm/FormRenderer';
import { sendRating } from '../../../../../controllers/TicketController/TicketService';
interface SurveyDialogProps {
    /** Function that handles when closes */
    close: Function;
    /** Function inherited from the dialog to activate the loader */
    setLoading?: Function;
    /** the form to display */
    survey: any;
    /** The values sent by the user to display */
    values: Record<string, any>;
    /** Function called to handle the confirmation of the sumitted survey */
    handleConfirm: Function;
}
/**
 * Component rendered inside the postview dialog.
 */
function SurveyDialog({
    survey,
    values,
    handleConfirm,
}: SurveyDialogProps): JSX.Element {
    return (
        <div className={styles.container}>
            <label className={styles.helperMessageLbl}>
                {survey.helperMessage ??
                    '¿Cómo calificaría la atención que recibió?'}
            </label>
            {survey && values && (
                <div className={styles.widgetContainer}>
                    <EurekaForm
                        form={survey}
                        values={values}
                        customSubmit={async (values): Promise<void> => {
                            const params = location.pathname.split('/');
                            const id = params[params.length - 1];
                            const body = {
                                idSurvey: survey.idSurvey,
                                surveyValues: values,
                            };
                            await sendRating(id, body);
                            handleConfirm();
                        }}
                        sendLabel="Calificar"
                    />
                </div>
            )}
        </div>
    );
}

export default SurveyDialog;
