import React from 'react';
import styles from './FormPostView.module.css';
import EurekaForm from '../../../../shared/EurekaForm/FormRenderer';

interface FormPostViewProps {
    /** Function that handles when closes */
    close: Function;
    /** Function inherited from the dialog to activate the loader */
    setLoading?: Function;
    /** the form to display */
    form: any;
    /** The values sent by the user to display */
    formValues: Record<string, any>;
    partial: boolean;
}
/**
 * Component rendered inside the postview dialog.
 */
function FormPostView({
    form,
    formValues,
    partial,
}: FormPostViewProps): JSX.Element {
    return (
        <div
            className={styles.container}
            style={{
                backgroundColor: form?.style?.backgroundColor ?? 'white',
            }}
        >
            {form && formValues && (
                <div className={styles.widgetContainer}>
                    <EurekaForm
                        postview={true}
                        partial={partial}
                        form={form}
                        values={formValues}
                    />
                </div>
            )}
        </div>
    );
}

export default FormPostView;
