import styles from './Rating.module.css';
import { Survey } from '../../../../@Types/Survey';
import {
    Rating as RatingType,
    TextArea,
} from '@arquimedes.co/eureka-forms/dist/@Types/FormStep';
import { TicketRating } from '../../../../@Types/Ticket';
import Rating from '../../../../shared/Rating/Rating';
import { useRef, useState } from 'react';
import RoundedButton from '../../../../shared/RoundedButton/RoundedButton';
import React from 'react';
import Dialog from '../../../../shared/Dialog/Dialog';
import EurekaForm from '../../../../shared/EurekaForm/FormRenderer';
import RoundedTextField from '../../../../shared/RoundedTextField/RoundedTextField';
import { Branding } from '../../../../@Types/Branding';
interface RatingProps {
    name: string;
    rating: TicketRating;
    survey: Survey;
    branding: Branding;
}
function RatingComponent({
    name,
    survey,
    rating,
    branding,
}: RatingProps): JSX.Element {
    const [showRating, setShowRating] = useState(false);
    const ratingRef = useRef<any>();

    const primaryStep: RatingType =
        survey.steps[rating.uniqueSteps.primaryRatingStep ?? ''];

    const primaryValue = rating.surveyValues[primaryStep?.id ?? ''];

    const commentStep: TextArea =
        survey.steps[survey.uniqueSteps.commentStep ?? ''];

    const commentValue = rating.surveyValues[commentStep?.id ?? '']?.value;

    return (
        <React.Fragment>
            {showRating && (
                <Dialog
                    open={true}
                    disableEnforceFocus
                    onClose={(): void => setShowRating(false)}
                >
                    <div className={styles.container}>
                        <div className={styles.widgetContainer}>
                            <EurekaForm
                                postview={true}
                                form={survey}
                                values={rating.surveyValues}
                            />
                        </div>
                    </div>
                </Dialog>
            )}
            <div className={styles.container}>
                {primaryStep && primaryValue !== undefined && (
                    <Rating
                        name={name}
                        value={primaryValue}
                        onChange={(): void => {}}
                        inputRef={ratingRef}
                        isAction
                        disabled
                        focusColor={branding.colors.contrastColor}
                        unSelectedColor={'#b8b8b8'}
                        type={primaryStep.ratingType}
                    />
                )}
                {commentStep && commentValue && (
                    <div className={styles.textField}>
                        <RoundedTextField
                            type="text"
                            label={commentStep.label}
                            multiline={true}
                            cantEdit
                            minRows={3}
                            value={commentValue}
                        />
                    </div>
                )}
                <div className={styles.formBtnContainer}>
                    <RoundedButton
                        onClick={(): void => {
                            setShowRating(true);
                        }}
                        backgroundColor={branding.colors.primaryColor}
                        text="Ver Calificación"
                        fontSize={'14px'}
                        padding="6px 12px"
                    />
                </div>
            </div>
        </React.Fragment>
    );
}

export default RatingComponent;
