import { AnyAction } from 'redux';
import * as Actions from './SiteActions';
import { Organization } from '../../@Types/@Types';
import { Branding } from '../../@Types/Branding';
export interface SiteState {
    /** Current Organization, undefined if app has not loaded, null if org was not found */
    organization: Organization | undefined | null;
    branding: Branding | undefined;
}

const initialSiteState = {
    organization: undefined,
    branding: undefined,
};

/**
 * Redux Reducer that handles SiteAction triggers
 * @param state The current state
 * @param action The action that has been triggered
 * @returns the new state
 */
function siteReducer(
    state: SiteState = initialSiteState,
    action: AnyAction
): SiteState {
    if (Actions.loadSuccess.match(action)) {
        if (!action.payload.customBrandings) {
            if (document) {
                document.title = action.payload.branding.name;
            }
        }
        return {
            ...state,
            organization: action.payload,
            branding: !action.payload.customBrandings
                ? action.payload.branding
                : undefined,
        };
    } else if (Actions.loadError.match(action)) {
        return { ...state, organization: null };
    } else if (Actions.setBranding.match(action)) {
        if (!state.branding) {
            if (document) {
                document.title = action.payload.name;
            }
        }
        return { ...state, branding: action.payload };
    }
    return state;
}

export default siteReducer;
