let interval: NodeJS.Timeout;
/**
 * Function called to create a socket
 * @param idAgent The user id of the account that is logged in
 * @param idOrganization The idOrganization of the current account
 */
export const createSocket = async (
    idTicket: string,
    idOrganization: string
): Promise<WebSocket> => {
    const socket = new WebSocket(
        'wss://sockets.' +
            process.env.REACT_APP_DOMAIN +
            '/?idTicket=' +
            idTicket +
            '&idOrganization=' +
            idOrganization
    );
    socket.addEventListener('open', function () {
        socket.send('Connected');
    });
    return socket;
};
/**
 * Function called to add a listener over a socket
 * @param socket the target socket
 * @param callback the callback function when a new message arrives
 */
export const onMessage = async (
    socket: WebSocket,
    callback: Function
): Promise<void> => {
    socket.addEventListener('message', function (event) {
        clearInterval(interval);
        interval = setInterval(function () {
            socket.send('Idle Message');
        }, 540000);
        callback(JSON.parse(event.data));
    });
};
/**
 * Function called to add a listener over a socket
 * @param socket the target socket
 * @param callback the callback function when a new message arrives
 */
export const removeListener = async (
    socket: WebSocket,
    callback: Function
): Promise<void> => {
    socket.removeEventListener('message', function (event) {
        callback(JSON.parse(event.data));
    });
};
/**
 * Function called to add a listener over a socket
 * @param socket the target socket
 * @param callback the callback function when a new message arrives
 */
export const onClose = async (
    socket: WebSocket,
    callback: Function
): Promise<void> => {
    socket.addEventListener('close', function (event) {
        console.error('Socket Closed', new Date());
        clearInterval(interval);
        callback(event);
    });
};
export default {
    createSocket,
};
