/* eslint-disable no-console */
import { AnyAction } from '@reduxjs/toolkit';
import { takeLatest, call, put, fork, select, all } from 'redux-saga/effects';
import { postFile, getUploadUrls } from '../FilesController/FilesService';
import * as Actions from './TextEditorActions';
import { AtomicBlockUtils } from 'draft-js';
import maxSize from '../../constants/Files/MaxSize';
import { calcImageEntityData } from '../../utils/draftFunctions';

/**
 * Function called when files need to be uploaded
 * @param action
 */
function* fetchFiles(action: AnyAction): any {
    try {
        if (Actions.uploadFiles.match(action)) {
            const files = action.payload;
            const fileLinks = yield call(getUploadUrls, files);
            const fetched = [];
            const errors = [];
            for (let i = 0; i < files.length; i++) {
                if (fileLinks[i] === 'ERROR:INVALIDEXTENSION') {
                    errors.push({
                        file: files[i],
                        errorMsg:
                            'La extención .' +
                            files[i].name.split('.').pop() +
                            ' no es válida',
                    });
                } else if (files[i].size <= maxSize) {
                    fetched.push({ file: files[i], postInfo: fileLinks[i] });
                }
            }

            yield put(
                Actions.filesUploading({
                    errors,
                    fetched,
                })
            );
        }
    } catch (error) {
        console.error(error);
    }
}

async function wait(): Promise<any> {
    return new Promise((resolve) => setTimeout(resolve, 1000));
}

function* uploadFile(file: File, postInfo: any): any {
    try {
        const [resp] = yield all([call(postFile, file, postInfo), call(wait)]);
        yield put(
            Actions.fileDone({
                file,
                S3Key: resp.S3Key,
                fileName: resp.fileName,
            })
        );
    } catch (error) {
        console.error(error);
    }
}

/**
 * Function called to upload the file of an image
 * @param action
 */
function* uploadFiles(action: AnyAction): any {
    if (Actions.filesUploading.match(action)) {
        yield all(
            action.payload.fetched.map((file) =>
                uploadFile(file.file, file.postInfo)
            )
        );
    }
}

function* watchFetchFiles(): any {
    yield takeLatest([Actions.Types.UPLOAD_FILE], fetchFiles);
}

function* watchUploadFiles(): any {
    yield takeLatest([Actions.Types.FILES_UPLOAD_FILES], uploadFiles);
}

/**
 * Function called when new image needs to be uploaded
 * @param action
 */
function* startImage(action: AnyAction): any {
    if (Actions.uploadImage.match(action)) {
        const editorInfo = yield select((state) => state.textEditor);
        const localSrc = URL.createObjectURL(action.payload);
        const entityData = yield call(calcImageEntityData, localSrc);
        const entityKey = editorInfo.editorState
            .getCurrentContent()
            .createEntity('IMAGE', 'MUTABLE', entityData)
            .getLastCreatedEntityKey();
        const newEditorState = AtomicBlockUtils.insertAtomicBlock(
            editorInfo.editorState,
            entityKey,
            ' '
        );
        yield put(
            Actions.imageFetch({
                imageKey: entityData.imageKey,
                image: {
                    localSrc,
                    file: action.payload,
                    imageKey: entityData.imageKey,
                    state: 'FETCHING',
                },
                editorState: newEditorState,
            })
        );
    }
}

/**
 * Function called to fetches the upload link of an image
 * @param action
 */
function* fetchImage(action: AnyAction): any {
    try {
        if (Actions.imageFetch.match(action)) {
            let result = yield call(getUploadUrls, [action.payload.image.file]);
            yield put(
                Actions.imageUploadFile({
                    imageKey: action.payload.imageKey,
                    uploadInfo: result[0],
                })
            );
        }
    } catch (error) {
        console.error(error);
        yield put(
            Actions.imageError({
                imageKey: action.payload.imageKey,
                message: error as string,
            })
        );
    }
}

/**
 * Function called to upload the file of an image
 * @param action
 */
function* uploadImage(action: AnyAction): any {
    try {
        if (Actions.imageUploadFile.match(action)) {
            const images = yield select((state) => state.textEditor.images);
            const image = images[action.payload.imageKey];
            let result = yield call(
                postFile,
                image.file,
                action.payload.uploadInfo
            );
            yield put(
                Actions.imageDone({
                    imageKey: action.payload.imageKey,
                    S3Key: result.S3Key,
                })
            );
        }
    } catch (error) {
        console.error(error);
        yield put(
            Actions.imageError({
                imageKey: action.payload.imageKey,
                message: error as string,
            })
        );
    }
}

function* watchStartImage(): any {
    yield takeLatest([Actions.Types.UPLOAD_IMAGE], startImage);
}

function* watchFetchImage(): any {
    yield takeLatest([Actions.Types.IMAGE_FETCH], fetchImage);
}

function* watchUploadImage(): any {
    yield takeLatest([Actions.Types.IMAGE_UPLOAD_FILE], uploadImage);
}

export default [
    fork(watchFetchFiles),
    fork(watchUploadFiles),
    fork(watchStartImage),
    fork(watchFetchImage),
    fork(watchUploadImage),
];
