import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { match, RouteComponentProps, withRouter } from 'react-router-dom';
import { Ticket } from '../../@Types';
import { fetchTicket } from '../../controllers/TicketController/TicketService';
import EurekaForm from '../../shared/EurekaForm/FormRenderer';
import { RootState } from '../../utils/_store';
import Action from '../TicketDetail/Action/Action';
import styles from './TicketTrace.module.css';
import { setBranding } from '../../controllers/_SiteController/SiteActions';

interface IdTicketRouteParam {
    /** Current Ticket */
    idTicket?: string;
    /** The id of the currentProject */
    idProject: string;
}
interface TicketsPageProps extends RouteComponentProps {
    match: match<IdTicketRouteParam>;
}
function TicketTrace({
    match: {
        params: { idTicket },
    },
}: TicketsPageProps): JSX.Element {
    const [ticket, setTicket] = useState<Ticket | undefined>(undefined);
    const siteInfo = useSelector((state: RootState) => state.site);
    const dispatch = useDispatch();
    const fetchInfo = async (): Promise<void> => {
        if (idTicket) {
            const ticketResponse = await fetchTicket(idTicket);
            const branding =
                ticketResponse?.branding ?? siteInfo.organization?.branding;
            if (branding) dispatch(setBranding(branding));
            if (ticketResponse) {
                setTicket(ticketResponse);
            }
        }
    };
    //TODO: Manejar el tema del branding mucho mejor!
    useEffect(() => {
        if (siteInfo.organization && ticket && !siteInfo.branding) {
            dispatch(setBranding(siteInfo.organization.branding));
        }
    }, [siteInfo.organization, ticket]);
    useEffect(() => {
        fetchInfo();
    }, []);
    if (ticket && siteInfo.branding) {
        return (
            <div className={styles.container}>
                <div className={styles.titleLbl}>Caso {ticket.caseNumber}</div>
                <div className={styles.formContainer}>
                    {ticket.form && (
                        <div className={styles.erkForm}>
                            <div className={styles.creationDate}>
                                Fecha de creación:{' '}
                                {ticket.creation_date.toLocaleDateString() +
                                    ' ' +
                                    ticket.creation_date?.toLocaleTimeString(
                                        undefined,
                                        { second: undefined }
                                    )}
                            </div>
                            <EurekaForm
                                postview={true}
                                partial={ticket.entry?.type === 'WHATSAPP'}
                                form={ticket.form}
                                values={ticket.formValues}
                            />
                        </div>
                    )}
                    {ticket.form === undefined && (
                        <div className={styles.empty}>
                            Formulario no Disponible
                        </div>
                    )}
                </div>

                <div className={styles.activityLbl}>Comunicaciones:</div>
                <div
                    className={styles.listContCont}
                    data-testid="activity_list_container"
                >
                    {ticket.actions.map((element, index) => (
                        <Action
                            element={element}
                            ticket={ticket}
                            key={index}
                            last={index === ticket.actions.length - 1}
                            xsm={false}
                        />
                    ))}
                    {(ticket.actions.length === undefined ||
                        ticket.actions.length === 0) && (
                        <div className={styles.empty}>Sin Comunicaciones</div>
                    )}
                </div>
            </div>
        );
    }
    return <div className={styles.container}></div>;
}
export default withRouter((props: any) => <TicketTrace {...props} />);
