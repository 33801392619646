import styles from './TextEditor.module.css';
import React, { useEffect, useRef, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Attachments from './Attachments/Attachments';
import { ClickAwayListener } from '@material-ui/core';
import ImageButton from './Images/ImageButton';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../utils/_store';
import FullscreenRoundedIcon from '@material-ui/icons/FullscreenRounded';
import { FileObject } from '../../controllers/TextEditorController/TextEditorReducer';
import {
    editorChange,
    removeFile,
    setFocus,
    uploadFiles,
    uploadImage,
} from '../../controllers/TextEditorController/TextEditorActions';
import { retrieveImageFromClipboardAsBlob } from '../../utils/FileFunctions';
import getImageComponent from './Images/ImageComponent';
interface ControlledTextEditorProps {
    /** Placeholder of the text editor*/
    placeholder: string;
    /** If the content cant be changed */
    cantEdit?: boolean;
    /** The delay to focus on the editor */
    focusDelay?: number;
    /** ref of the parent so that clicks on it dont unfocus */
    parentRef?: any;
    /** Function called to handle a fullscreen toggle */
    handleFullScreen?: Function;
}

function TextEditor({
    parentRef,
    focusDelay,
    placeholder,
    cantEdit = false,
    handleFullScreen,
}: ControlledTextEditorProps): JSX.Element {
    const branding = useSelector((state: RootState) => state.site.branding!);
    const [hovering, setHovering] = useState(false);
    const [firstTime, setFirstTime] = useState(true);
    const dispatch = useDispatch();
    const editorInfo = useSelector((state: RootState) => state.textEditor);

    const editorRef = useRef<any>();

    useEffect(() => {
        if (!firstTime) {
            dispatch(editorChange(editorInfo.editorState));
            editorRef?.current?.focusEditor?.();
        }
    }, [placeholder]);
    // TODO: Bug de que el primer medio segundo no puedo escribir porque no esta focuseado.
    useEffect(() => {
        if (focusDelay) {
            setTimeout(() => {
                editorRef?.current?.focusEditor?.();
            }, focusDelay);
        }
        setFirstTime(false);
    }, []);

    const handlePaste = (event: any): void => {
        if (editorInfo.focus) {
            retrieveImageFromClipboardAsBlob(event, (blob: any) => {
                if (blob) {
                    dispatch(uploadImage(blob));
                }
            });
        }
    };

    useEffect((): any => {
        if (editorInfo.focus) {
            document.addEventListener('paste', handlePaste);
            return (): void => {
                document.removeEventListener('paste', handlePaste);
            };
        }
    }, [editorInfo.focus]);

    const calcStyle = (): React.CSSProperties => {
        if (editorInfo.focus) {
            return {
                border: '2px solid ' + branding.colors.secondaryColor,
                marginTop: -1,
                marginLeft: -1,
                '--editorFocusColor': branding.colors.secondaryColor,
            } as React.CSSProperties;
        } else if (hovering) {
            return {
                border: '1px solid ' + branding.colors.secondaryColor,
                '--editorFocusColor': branding.colors.secondaryColor,
            } as React.CSSProperties;
        } else {
            return {
                border: '1px solid var(--outlineGrey)',
                '--editorFocusColor': branding.colors.secondaryColor,
            } as React.CSSProperties;
        }
    };

    const customBlockRender = (block: any, config?: any): any => {
        if (block.getType() === 'atomic') {
            const contentState = config.getEditorState().getCurrentContent();
            const entityKey = block.getEntityAt(0);
            if (entityKey) {
                const entity = contentState.getEntity(entityKey);
                if (entity && entity.type === 'IMAGE') {
                    return {
                        component: getImageComponent(config),
                        editable: false,
                    };
                }
            }
        }
        return undefined;
    };

    const calcCustomButtons = (): JSX.Element[] => {
        const btns = [
            <Attachments
                files={editorInfo.files}
                hasError={editorInfo.filesError}
                handleUpload={(files: File[]): void => {
                    dispatch(uploadFiles(files));
                }}
                handleRemove={(file: FileObject): void => {
                    dispatch(removeFile(file));
                }}
                branding={branding}
            />,
            <ImageButton
                handleAdd={(file: File): void => {
                    dispatch(uploadImage(file));
                }}
            />,
        ];
        if (handleFullScreen !== undefined) {
            btns.push(
                <button
                    className={styles.launchBtn}
                    onClick={(): void => {
                        handleFullScreen();
                    }}
                >
                    <FullscreenRoundedIcon fontSize="inherit" />
                </button>
            );
        }
        return btns;
    };
    let editorClassName = styles.editor + ' Erk-TextEditor';
    if (!editorInfo.editorState.getCurrentContent().hasText()) {
        if (
            editorInfo.editorState
                .getCurrentContent()
                .getBlockMap()
                .first()
                .getType() !== 'unstyled'
        ) {
            editorClassName += ' RichEditor-hidePlaceholder';
        }
    }
    return (
        <ClickAwayListener
            mouseEvent="onMouseDown"
            onClickAway={(e: any): void => {
                if (
                    parentRef == undefined ||
                    (parentRef.current && !parentRef.current.contains(e.target))
                ) {
                    dispatch(setFocus(false));
                }
            }}
        >
            <div
                className={styles.textContainer + ' ErkC-TextEditorContainer'}
                style={calcStyle()}
                onMouseEnter={(): void => {
                    setHovering(true);
                }}
                onMouseLeave={(): void => {
                    setHovering(false);
                }}
            >
                {cantEdit && <div className={styles.disabledCurtain}></div>}

                <Editor
                    ref={editorRef}
                    onFocus={(): void => {
                        dispatch(setFocus(true));
                    }}
                    spellCheck
                    editorState={editorInfo.editorState}
                    onEditorStateChange={(newEditorState): void => {
                        dispatch(editorChange(newEditorState));
                    }}
                    toolbarClassName={styles.toolbar}
                    customBlockRenderFunc={customBlockRender}
                    editorClassName={editorClassName}
                    wrapperClassName={styles.wrapper}
                    stripPastedStyles
                    placeholder={placeholder}
                    toolbar={{
                        options: ['inline', 'list', 'history'],
                        inline: {
                            options: [
                                'bold',
                                'italic',
                                'underline',
                                'strikethrough',
                            ],
                        },
                        list: {
                            options: ['unordered'],
                        },
                    }}
                    toolbarCustomButtons={calcCustomButtons()}
                />
            </div>
        </ClickAwayListener>
    );
}
export default TextEditor;
