import React, { useState } from 'react';
import { ClientViewFormActivity } from '../../../../../@Types/Activity';
import FormRenderer from '../../../../../shared/EurekaForm/FormRenderer';
import RoundedButton from '../../../../../shared/RoundedButton/RoundedButton';
import Dialog from '../../../../../shared/Dialog/Dialog';
import styles from './ViewFormActivityAction.module.css';
import { Branding } from '../../../../../@Types/Branding';

export interface FormActivityProps {
    activity: ClientViewFormActivity;
    values: Record<string, any>;
    /** The current org's styles */
    branding: Branding;
}

function FormActivityComponent({
    values,
    activity,
    branding,
}: FormActivityProps): JSX.Element {
    const [showDialog, setShowDialog] = useState(false);

    const { rootSteps, previewSteps, steps, size, label, classifiers } =
        activity;

    const renderForm = (): JSX.Element | void => {
        if (rootSteps && rootSteps.length) {
            return (
                <React.Fragment>
                    {previewSteps && previewSteps.length > 0 && (
                        <FormRenderer
                            classifiers={classifiers}
                            rootSteps={previewSteps}
                            postview={true}
                            form={{
                                steps,
                                size: {
                                    blockNum: 2,
                                    blockSize: 210,
                                    spacingSize: 20,
                                },
                            }}
                            values={values}
                        />
                    )}
                    {rootSteps.length > 0 &&
                        (previewSteps.length !== rootSteps.length ||
                            previewSteps.find(
                                (idStep) => !rootSteps.includes(idStep)
                            )) && (
                            <div>
                                <RoundedButton
                                    onClick={(): void => {
                                        setShowDialog(true);
                                    }}
                                    backgroundColor={
                                        branding.colors.primaryColor
                                    }
                                    text={
                                        label ??
                                        (rootSteps.length > 0
                                            ? 'Ver más'
                                            : 'Ver Formulario')
                                    }
                                    fontSize={'15px'}
                                    padding="8px 16px"
                                />
                            </div>
                        )}
                </React.Fragment>
            );
        }
    };

    return (
        <React.Fragment>
            {showDialog && (
                <Dialog
                    maxWidth="100%"
                    open={true}
                    disableEnforceFocus
                    onClose={(): void => setShowDialog(false)}
                >
                    <div className={styles.formDialogContainer}>
                        <div className={styles.containerLbl}>
                            {activity.name}
                        </div>
                        <FormRenderer
                            postview={true}
                            classifiers={classifiers}
                            rootSteps={rootSteps}
                            form={{
                                size,
                                steps,
                            }}
                            values={values}
                        />
                    </div>
                </Dialog>
            )}
            <div className={styles.container}>
                {activity.helperText && (
                    <label
                        className={styles.helperMessageLbl}
                        style={{
                            marginBottom:
                                previewSteps && previewSteps.length > 0
                                    ? 10
                                    : 20,
                        }}
                    >
                        {activity.helperText}
                    </label>
                )}
                {renderForm()}
            </div>
        </React.Fragment>
    );
}

export default FormActivityComponent;
