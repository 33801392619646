import Rating from '../../../../shared/Rating/Rating';
import { Survey } from '../../../../@Types/Survey';
import styles from './RateComponent.module.css';
import { Rating as RatingType } from '@arquimedes.co/eureka-forms/dist/@Types/FormStep';
import { useEffect, useRef, useState } from 'react';
import RoundedButton from '../../../../shared/RoundedButton/RoundedButton';
import React from 'react';
import Dialog from '../../../../shared/Dialog/Dialog';
import SurveyDialog from './SurveyDialog/SurveyDialog';
import { useDispatch, useSelector } from 'react-redux';
import {
    refresh,
    setShowRatingConfirmation,
} from '../../../../controllers/TicketController/TicketActions';
import { RootState } from '../../../../utils/_store';
import { RatingTypes } from '../../../../constants/SurveyTypes';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface RateComponentProps {
    survey: Survey;
}

function RateComponent({
    history,
    survey,
}: RateComponentProps & RouteComponentProps): JSX.Element {
    const idOrganization = useSelector(
        (state: RootState) => state.site.organization?.idOrganization
    );
    const branding = useSelector((state: RootState) => state.site.branding!);
    const primaryStep: RatingType =
        survey.steps[survey.uniqueSteps?.primaryRatingStep ?? ''];
    const [defaultValues, setDefaultValues] = useState<
        Record<string, unknown> | undefined
    >(idOrganization === 'melendez' ? {} : undefined);

    const showConfirmation = useSelector(
        (state: RootState) => state.ticketDetail.showRatingConfirm
    );

    const dispatch = useDispatch();
    //TODO: Load default Values from url? and if survey in url open dialog!

    const ratingRef = useRef<any>();
    useEffect(() => {
        const primaryStep =
            survey.steps[survey.uniqueSteps?.primaryRatingStep ?? ''];
        const urlParams = new URLSearchParams(window.location.search);
        const rating = parseInt(urlParams.get('rating') ?? '');
        if (
            primaryStep?.type === 'RATING' &&
            rating !== undefined &&
            !isNaN(rating)
        ) {
            switch (primaryStep.ratingType) {
                case RatingTypes.LIKE:
                    if (rating === 0 || rating === 1) {
                        setDefaultValues({
                            [primaryStep.id]: rating,
                        });
                    }
                    break;
                case RatingTypes.SATISFACTION:
                    if (rating > 0 && rating <= 4) {
                        setDefaultValues({
                            [primaryStep.id]: rating,
                        });
                    }
                    break;
                case RatingTypes.SCALE:
                    if (rating > 0 && rating <= 5) {
                        setDefaultValues({
                            [primaryStep.id]: rating,
                        });
                    }
                    break;
                default:
                    break;
            }
        }
        if (urlParams.toString()) {
            urlParams.delete('rating');
            const searchString = urlParams.toString();
            history.replace({
                search: searchString ? `?${searchString}` : '',
            });
        }
        //TODO: Aca seria interesante notificarle al servidor si hicieron click en el correo pero no la completaron.
    }, []);
    return (
        <React.Fragment>
            {defaultValues !== undefined && !showConfirmation && (
                <Dialog
                    open={true}
                    disableEnforceFocus
                    onClose={(): void => setDefaultValues(undefined)}
                >
                    <SurveyDialog
                        close={(): void => setDefaultValues(undefined)}
                        values={defaultValues}
                        survey={survey}
                        handleConfirm={(): void => {
                            dispatch(refresh());
                            dispatch(setShowRatingConfirmation(true));
                        }}
                    />
                </Dialog>
            )}

            <div className={styles.container}>
                <label className={styles.helperMessageLbl}>
                    {survey.helperMessage ??
                        '¿Cómo calificaría la atención que recibió?'}
                </label>
                {primaryStep && (
                    <Rating
                        name={'RATE'}
                        value={null}
                        onChange={(value: any): void => {
                            setDefaultValues({ [primaryStep.id]: value });
                        }}
                        inputRef={ratingRef}
                        focusColor={branding.colors.contrastColor}
                        unSelectedColor={'#b8b8b8'}
                        type={primaryStep.ratingType}
                    />
                )}
                <div className={styles.formBtnContainer}>
                    <RoundedButton
                        onClick={(): void => {
                            setDefaultValues({});
                        }}
                        backgroundColor={branding.colors.primaryColor}
                        text="Calificar"
                        fontSize={'18px'}
                        padding="8px 16px"
                    />
                </div>
            </div>
        </React.Fragment>
    );
}

export default withRouter(RateComponent);
