import styles from './PayloadConfirmation.module.css';
import { EditorState, convertFromRaw } from 'draft-js';
import FileComponent from '../../../../shared/FileComponent/FileComponent';
import { getRawText } from '../../../../utils/draftFunctions';
import { calcDate } from '../../Action/Action';
import RoundedButton from '../../../../shared/RoundedButton/RoundedButton';
import { Payload, PayloadImage } from '../../../../@Types/Payload';
import DraftRenderer from '../../../../shared/DraftRenderer/DraftRenderer';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../utils/_store';
interface PayloadConfirmationProps {
    /** Function called to close the dialog */
    close: Function;
    /**  The payload to confirm*/
    payload: Payload;
    /** function called on confirm */
    handleConfirm: Function;
    /** Function called to handle loader */
    setLoading?: Function;
    /** The current images to render */
    images: Record<string, PayloadImage>;
}

function PayloadConfirmation({
    handleConfirm,
    setLoading,
    payload,
    images,
    close,
}: PayloadConfirmationProps): JSX.Element {
    const branding = useSelector((state: RootState) => state.site.branding!);
    const onSubmit = async (): Promise<void> => {
        try {
            setLoading?.(true);
            await handleConfirm();
            setLoading?.(false);
            close();
        } catch (error) {
            setLoading?.(false);
            // eslint-disable-next-line no-console
            console.error(error);
        }
    };
    const editorState = EditorState.createWithContent(
        convertFromRaw(getRawText(payload.draft, payload.text))
    );

    const hasText = editorState.getCurrentContent().hasText();

    return (
        <div
            className={styles.container}
            data-testid="payload_confirmation_container"
        >
            <div className={styles.containerLbl}>Confirmar Mensaje</div>
            <div
                className={styles.actionTitleContainerUser}
                style={{ backgroundColor: branding.colors.secondaryColor }}
            >
                <div className={styles.actionTitle}>
                    <span className={styles.boldName}>Yo</span>
                </div>
                <label className={styles.actionDate}>
                    {calcDate({ creation_date: new Date() } as any)}
                </label>
            </div>
            <div className={styles.msgContainer}>
                {hasText && (
                    <div
                        className={styles.textContainer}
                        data-testid="payloadConfirmation_textContainer"
                    >
                        <DraftRenderer
                            editorState={editorState}
                            images={images}
                        />
                    </div>
                )}

                {payload.files && payload.files.length > 0 && (
                    <div
                        className={styles.filesContainer + ' flexbox'}
                        data-testid="payloadConfirmation_filesContainer"
                        style={
                            hasText
                                ? {
                                      borderTop: '1px solid var(--light-grey)',
                                  }
                                : {}
                        }
                    >
                        {payload.files.map((file, index) => (
                            <div className={styles.fileContainer} key={index}>
                                <FileComponent
                                    color={branding.colors.contrastColor}
                                    file={file}
                                    marginLeft="0px"
                                    showToolTip
                                />
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className={styles.descriptionLbl}>
                ¿Estás seguro que deseas enviar este mensaje?
            </div>
            <div className={styles.btnsContainer}>
                <RoundedButton
                    text="Cancelar"
                    onClick={(): void => {
                        close();
                    }}
                    backgroundColor="var(--light-grey)"
                    color="var(--accent)"
                    fontSize="16px"
                />

                <div className={styles.btn}>
                    <RoundedButton
                        text={'Enviar'}
                        backgroundColor={branding.colors.contrastColor}
                        color="white"
                        onClick={(): void => {
                            onSubmit();
                        }}
                        fontSize="16px"
                    />
                </div>
            </div>
        </div>
    );
}

export default PayloadConfirmation;
