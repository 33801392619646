import React, { useEffect } from 'react';
import RouterComponent from './Router';
import './styles/DraftEditor.css';
import { useDispatch, useSelector } from 'react-redux';
import { load } from './controllers/_SiteController/SiteActions';
import { RootState } from './utils/_store';
import {
    createGenerateClassName,
    StylesProvider,
    useMediaQuery,
} from '@material-ui/core';
export const responsiveBreakpoint = 768;
const generateClassName = createGenerateClassName({
    seed: 'ErkC',
    productionPrefix: 'eureka-clients',
});

function App(): JSX.Element {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(load());
    }, []);
    const siteInfo = useSelector((state: RootState) => state.site);

    const mobileWidth = useMediaQuery(`(max-width:${responsiveBreakpoint}px)`, {
        noSsr: true,
    });
    const mobileHeight = useMediaQuery('(max-height:420px)', {
        noSsr: true,
    });

    if (siteInfo.organization !== null) {
        return (
            <StylesProvider generateClassName={generateClassName}>
                <div
                    className="app-container"
                    style={{
                        visibility: siteInfo.branding ? 'visible' : 'hidden',
                    }}
                >
                    <RouterComponent
                        mobile={mobileWidth || mobileHeight}
                        visible={siteInfo.branding !== undefined}
                    />
                </div>
            </StylesProvider>
        );
    } else {
        return <div>404 Org not found</div>;
    }
}

export default App;
