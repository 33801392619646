import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import styles from './RatingConfirmationDialog.module.css';
import Dialog from '../../../../../shared/Dialog/Dialog';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../utils/_store';
interface RatingConfirmationDialogProps {
    onClose: Function;
}

function RatingConfirmationDialog({
    onClose,
}: RatingConfirmationDialogProps): JSX.Element {
    const branding = useSelector((state: RootState) => state.site.branding!);
    return (
        <Dialog
            open={true}
            disableEnforceFocus
            onClose={(): void => {
                onClose();
            }}
            borderRadius={10}
        >
            <div className={styles.confirmationContainer}>
                <div className={styles.confirmContainer}>
                    <div
                        className={styles.checkContainer}
                        style={{ color: branding.colors.primaryColor }}
                    >
                        <SentimentVerySatisfiedIcon
                            fontSize="inherit"
                            style={{ fontSize: '160px' }}
                        />
                    </div>
                    <div className={styles.messageContainer}>
                        Tu calificación ha sido registrada!
                    </div>
                </div>
            </div>
        </Dialog>
    );
}

export default RatingConfirmationDialog;
