import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setBranding } from '../../controllers/_SiteController/SiteActions';
import { RootState } from '../../utils/_store';

function Home(): JSX.Element {
    const { branding, organization } = useSelector(
        (state: RootState) => state.site
    );
    const dispatch = useDispatch();
    useEffect(() => {
        if (!branding && organization) {
            dispatch(setBranding(organization.branding));
        }
    }, [organization]);
    return <div>HOME</div>;
}
export default Home;
