import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import CropOriginalRoundedIcon from '@material-ui/icons/CropOriginalRounded';
import AttachFileRoundedIcon from '@material-ui/icons/AttachFileRounded';
import { IconButton } from '@material-ui/core';
import { getAcceptedExtensions } from '../../../../constants/Files/extensions';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../utils/_store';
interface StyleProps {
    /** The color of the outline when selected and hovered on */
    focusColor?: string;
}
const useTextfieldStyles = (props: StyleProps): Function =>
    makeStyles(() => ({
        root: {
            borderRadius: '10px 10px',
            backgroundColor: 'var(--primary1)',
            padding: '0px',
            '& input, textarea': {
                cursor: 'text',
                padding: '0px',
                paddingTop: 3,
                color: 'var(--accent)',
                fontSize: 15,
                minHeight: 21,
                '&::placeholder': {
                    fontSize: 15,
                },
                backgroundColor: 'var(--white)',
                // borderRadius: props.multiline ? '0px' : props.borderRadius,
            },
            '& input + fieldset': {
                borderRadius: '10px 10px',
                padding: '0px',
            },
            '& .ErkC-MuiInput-underline:after': {
                // borderBottomColor: props.outlineColor,
            },
            '& .ErkC-MuiOutlinedInput-root': {
                padding: '10px 10px 10px 14px',
                borderRadius: '10px 10px',
                '&.Mui-focused fieldset': {
                    borderColor: props.focusColor,
                    borderWidth: 2,
                    top: '-6px',
                    right: '-1px',
                },
                '& .ErkC-MuiOutlinedInput-notchedOutline': {
                    borderColor: props.focusColor,
                    '& span': {
                        fontSize: '1rem',
                        paddingRight: 0,
                        paddingLeft: 0,
                        paddingTop: 0,
                        paddingBottom: 0,
                    },
                },
            },
            '& .ErkC-MuiOutlinedInput-root:hover .ErkC-MuiOutlinedInput-notchedOutline':
                {
                    borderColor: props.focusColor,
                },
            '& label.Mui-focused': {
                color: props.focusColor,
            },
            '& .Mui-error': {
                color: 'var(--error)',
            },
        },
    }));

interface MobileTextFieldProps {
    /** Cant edit */
    cantEdit?: boolean;
    /** Function to call when image is selected */
    onImageSelected: Function;
    /** Function to call when file is selected */
    onFileSelected: Function;
}
function CustomTextfield({
    cantEdit = false,
    onImageSelected,
    onFileSelected,
    value,
    ...others
}: TextFieldProps & MobileTextFieldProps): JSX.Element {
    const branding = useSelector((state: RootState) => state.site.branding!);
    const classes = useTextfieldStyles({
        focusColor: branding.colors.secondaryColor,
    })();
    const filesInputRef = useRef<any>();
    const imageInputRef = useRef<any>();
    return (
        <TextField
            classes={classes}
            {...others}
            value={value}
            multiline
            maxRows={6}
            variant="outlined"
            size="small"
            fullWidth
            InputProps={{
                disabled: cantEdit,
                endAdornment:
                    (value as string).length === 0 ? (
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <input
                                type="file"
                                ref={imageInputRef}
                                style={{ display: 'none' }}
                                onChange={(e): void => {
                                    const newFiles = e.target.files;
                                    if (newFiles) {
                                        const filesArray = Array.from(newFiles);
                                        if (filesArray.length === 1) {
                                            onImageSelected(filesArray[0]);
                                        }
                                        if (imageInputRef.current) {
                                            imageInputRef.current.value = '';
                                        }
                                    }
                                }}
                                accept="image/*"
                            />
                            <IconButton
                                onClick={(): void => {
                                    imageInputRef?.current?.click?.();
                                }}
                                onMouseDown={(): void => {}}
                                style={{
                                    padding: '4px',

                                    marginTop: -4,
                                    marginBottom: -4,
                                }}
                            >
                                <CropOriginalRoundedIcon fontSize="inherit" />
                            </IconButton>
                            <input
                                type="file"
                                ref={filesInputRef}
                                style={{ display: 'none' }}
                                onChange={(e): void => {
                                    const newFiles = e.target.files;
                                    if (newFiles) {
                                        const filesArray = Array.from(newFiles);
                                        if (filesArray.length === 1) {
                                            onFileSelected(filesArray[0]);
                                        }
                                        if (filesInputRef.current) {
                                            filesInputRef.current.value = '';
                                        }
                                    }
                                }}
                                accept={getAcceptedExtensions()}
                            />
                            <IconButton
                                onClick={(): void => {
                                    filesInputRef?.current?.click?.();
                                }}
                                onMouseDown={(): void => {}}
                                style={{
                                    padding: '4px',
                                    marginTop: -4,
                                    marginLeft: 2,
                                    marginRight: -4,
                                    marginBottom: -4,
                                }}
                            >
                                <AttachFileRoundedIcon fontSize="inherit" />
                            </IconButton>
                        </div>
                    ) : undefined,
            }}
        />
    );
}

/**
 * Generic textfield with apps designs. Is class do to the use in the react-hook-forms library
 */
class MobileTextField extends React.Component<
    MobileTextFieldProps & TextFieldProps
> {
    render(): JSX.Element {
        return <CustomTextfield {...this.props} />;
    }
}
export default MobileTextField;
