import EurekaForm, { EurekaFormProps } from '@arquimedes.co/eureka-forms';
import { useSelector } from 'react-redux';
import { RootState } from '../../utils/_store';
import { useMemo } from 'react';
import styles from './FormRenderer.module.css';
import React from 'react';
import type { Form } from '@arquimedes.co/eureka-forms/dist/@Types';

interface FormRendererProps {
    form: Omit<Form, 'steps' | 'firstSection' | 'sections' | 'classifiers'> & {
        steps: Record<string, any>;
        sections?: Form['sections'];
        firstSection?: Form['firstSection'];
    };
    rootSteps?: string[];
    values?: Record<string, unknown>;
}

function FormRenderer({
    form,
    values,
    rootSteps = [],
    ...props
}: Omit<EurekaFormProps, 'formData' | 'valuesData'> &
    FormRendererProps): JSX.Element {
    const idOrganization = useSelector(
        (state: RootState) => state.site.organization?.idOrganization
    );

    const countryCode = useSelector(
        (state: RootState) => state.site.organization?.countryCode
    );

    const branding = useSelector((state: RootState) => state.site.branding!);

    const formRef = React.useRef<any>();

    const formData = useMemo(() => {
        const isCompleteForm =
            form.sections?.[form.firstSection ?? '']?.steps !== undefined;
        return isCompleteForm
            ? form
            : {
                  ...form,
                  firstSection: 'FIRST',
                  sections: {
                      FIRST: {
                          id: 'FIRST',
                          steps: rootSteps,
                          name: '',
                          nextSection: null,
                      },
                  },
                  style: {
                      primaryColor: branding.colors.secondaryColor,
                      secondaryColor: branding.colors.contrastColor,
                      secondaryContrastColor: 'white',
                  },
              };
    }, [form, rootSteps, values]);

    return (
        <div className={styles.container}>
            <div
                ref={formRef}
                className={styles.formContainer}
                style={
                    form
                        ? {
                              width:
                                  (form.size.blockSize +
                                      form.size.spacingSize) *
                                  form.size.blockNum,
                          }
                        : {}
                }
            >
                <EurekaForm
                    idOrganization={idOrganization}
                    {...props}
                    isWidget={true}
                    formData={formData as any}
                    valuesData={values}
                    countryCode={countryCode}
                />
            </div>
        </div>
    );
}

export default FormRenderer;
