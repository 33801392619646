export enum ActivityTypes {
    CLIENT_FORM = 'CLIENT_FORM',
    CLIENT_VIEW_FORM = 'CLIENT_VIEW_FORM',
    SCHEDULE = 'SCHEDULE',
    SCHEDULE_CANCEL = 'SCHEDULE_CANCEL',
}

export enum ActivityTriggerTypes {
    DEFAULT = 'DEFAULT',
    CONTRAST = 'CONTRAST',
    ERROR = 'ERROR',
}

export default ActivityTypes;
