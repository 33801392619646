import { AnyAction } from 'redux';
import * as Actions from './TicketActions';
import { Ticket } from '../../@Types/@Types';
export interface TicketDetailState {
    /** Current ticket, undefined if app has not loaded, null if org was not found */
    ticket: Ticket | undefined | null;
    /** The current Max width of draftJS imgs in the list. */
    imgMaxWidth: number;
    /** If the ticket is loading */
    loading: boolean;
    /** If the rated confirmation dialog should be displayed */
    showRatingConfirm: boolean;
    /** If the postview dialog should be displayed */
    showPostView: boolean;
}

const initialTicketDetailState = {
    ticket: undefined,
    loading: true,
    showRatingConfirm: false,
    showPostView: false,
    imgMaxWidth: 0,
};

/**
 * Redux Reducer that handles TicketDetailAction triggers
 * @param state The current state
 * @param action The action that has been triggered
 * @returns the new state
 */
function TicketDetailReducer(
    state: TicketDetailState = initialTicketDetailState,
    action: AnyAction
): TicketDetailState {
    if (Actions.reset.match(action)) {
        return {
            ...state,
            ticket: undefined,
            loading: true,
        };
    } else if (Actions.getSuccess.match(action)) {
        return {
            ...state,
            loading: false,
            ticket: action.payload.ticket,
        };
    } else if (Actions.setLoading.match(action)) {
        return {
            ...state,
            loading: action.payload,
        };
    } else if (Actions.setShowRatingConfirmation.match(action)) {
        return {
            ...state,
            showRatingConfirm: action.payload,
        };
    } else if (Actions.setShowPostview.match(action)) {
        return {
            ...state,
            showPostView: action.payload,
        };
    } else if (Actions.resetError.match(action)) {
        return { ...state, ticket: null };
    } else if (Actions.refresh.match(action)) {
        return { ...state, loading: action.payload };
    } else if (Actions.setImgMaxWidth.match(action)) {
        return { ...state, imgMaxWidth: action.payload };
    }
    return state;
}

export default TicketDetailReducer;
