import { useState } from 'react';
import { cloneElement } from 'react';
import Dialog from '@material-ui/core/Dialog';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import Loader from '../Loader/Loader';

interface CustomDialogProps {
    /** Function to handle close action */
    onClose: Function;
    /** If dialog is open */
    open: boolean;
    /** children to render */
    children?: React.ReactElement<any, string>;
    // If the dialog has embedded elements
    disableEnforceFocus?: boolean;
    /** The border of the dialog */
    borderRadius?: number;
    /** The maxwidth of the dialog */
    maxWidth?: number | string;
    /** The max height of the dialog */
    maxHeight?: number | string;
    /** If the dialog should show the loader */
    showLoader?: boolean;
    /** If the container is transparent */
    transparent?: boolean;
}
/**
 * Generic Dialog Component with embedded loader and multiple options
 */
function CustomDialog({
    onClose,
    open,
    children,
    borderRadius = 2,
    maxWidth = '90vw',
    maxHeight = '90vh',
    disableEnforceFocus = false,
    transparent = false,
    showLoader = true,
}: CustomDialogProps): JSX.Element {
    const [loading, setLoading] = useState(false);
    return (
        <Dialog
            disableEnforceFocus={disableEnforceFocus}
            PaperProps={{
                style: {
                    backgroundColor: transparent ? 'transparent' : 'white',
                    boxShadow: transparent ? 'none' : 'inherit',
                    borderRadius,
                    maxHeight: '100vh',
                    maxWidth: '100vw',
                    margin: 10,
                },
            }}
            onClose={(): void => {
                if (!loading) {
                    onClose();
                }
            }}
            open={open}
        >
            {loading && showLoader && (
                <Loader curtain={true} color={'var(--secondary)'} />
            )}
            <div
                style={{
                    right: '15px',
                    top: '10px',
                    fontSize: '26px',
                    position: 'absolute',
                    cursor: 'pointer',
                    zIndex: 19,
                }}
                onClick={(): void => {
                    if (!loading) {
                        onClose();
                    }
                }}
            >
                <CloseRoundedIcon fontSize="inherit" />
            </div>
            <div
                style={{
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    maxWidth,
                    width: 'fit-content',
                    maxHeight,
                }}
            >
                {children &&
                    cloneElement(children, {
                        loading,
                        setLoading,
                        onClose,
                    })}
            </div>
        </Dialog>
    );
}

export default CustomDialog;
