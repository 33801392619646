import React from 'react';
import { Button, withStyles } from '@material-ui/core';
import Loader from '../Loader/Loader';

interface ComponentProps {
    /** Button text */
    text: string;
    /** Background Color */
    backgroundColor?: string;
    /** Font Color */
    color?: string;
    /** Button Click Handle */
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    /** If button is disabled */
    disabled?: boolean;
    /** Text font size */
    fontSize?: number | string;
    /** Button padding '0 13px 0 13px' format */
    padding?: string;
    /** border radius */
    borderRadius?: number;
    /** Component height */
    height?: number | string;
    /** If text is bold */
    bold?: boolean;
    /** The width of the button */
    width?: string;
    /** If the button es loading */
    loading?: boolean;
    clickable?: boolean;
}

/**
 * Generic rounded button
 */
function RoundedButton({
    text,
    backgroundColor = 'var(--secondary)',
    color = 'white',
    onClick,
    disabled,
    fontSize = 14,
    padding = '0 13px 0 13px',
    borderRadius = 10,
    height = 28,
    bold = false,
    loading = false,
    width = 'fit-content',
}: ComponentProps): JSX.Element {
    const CustomButton = withStyles({
        root: {
            width,
            textTransform: 'none',
            fontFamily: 'Nunito',
            fontSize,
            borderRadius,
            padding,
            height: height,
            boxShadow: 'none',
            backgroundColor,
            color,
            '&:hover': {
                boxShadow: 'none',
                backgroundColor,
            },
            '&:disabled': {
                backgroundColor,
                color,
                opacity: 0.5,
            },
            cursor: 'pointer',
            fontWeight: bold ? 'bold' : 'normal',
        },
    })(Button);

    return (
        <CustomButton
            variant="contained"
            onMouseDown={onClick}
            disabled={disabled || loading}
        >
            {loading && <Loader size={20} color={'white'} />}
            <label
                style={{
                    visibility: loading ? 'hidden' : 'visible',
                    fontSize: 'inherit',
                    cursor: 'inherit',
                }}
            >
                {text}
            </label>
        </CustomButton>
    );
}
export default RoundedButton;
