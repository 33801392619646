import axiosInstance from '../../AxiosAPI';
import { Organization } from '../../@Types/Organization';

export async function fetchOrg(): Promise<Organization> {
    //TODO: Arreglar esta parte, es en caso de que la lambda este fria y no retorne antes de 30seg
    try {
        const response = await axiosInstance.get('/organization');
        const currentOrg = response.data as Organization;
        return currentOrg;
    } catch (error) {
        const response = await axiosInstance.get('/organization');
        const currentOrg = response.data as Organization;
        return currentOrg;
    }
}
