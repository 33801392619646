import React from 'react';
import { getLocale } from '../../../index';
import { Ticket } from '../../../@Types/Ticket';
import { TicketStateTypes } from '../../../constants/TicketStateTypes';
import RoundedButton from '../../../shared/RoundedButton/RoundedButton';
import styles from './Information.module.css';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';
import { setShowPostview } from '../../../controllers/TicketController/TicketActions';
import ProcessRenderer from '../../../shared/ProcessRenderer/ProcessRenderer';

interface InformationProps {
    ticket: Ticket;
    mobile: boolean;
}
function Information({ ticket }: InformationProps): JSX.Element {
    const branding = useSelector((state: RootState) => state.site.branding);
    const dispatch = useDispatch();
    const calcDate = (date: Date): string => {
        return format(date, 'PPPPp', {
            locale: getLocale(),
        });
    };
    const calcState = (stateEnum: string): string | void => {
        switch (stateEnum) {
            case TicketStateTypes.CLOSED: {
                return 'Cerrado';
            }
            default:
                return 'En curso';
        }
    };
    return (
        <React.Fragment>
            <div className={styles.infoPanel}>
                <h1 className={styles.infoTitle}>Información del caso</h1>
                <div className={styles.infoRows}>
                    <p>
                        <b>No. Radicado:</b> {ticket.caseNumber}
                        {ticket.CBR?.posventaCaseNum !== undefined
                            ? ' - ' + ticket.CBR.posventaCaseNum
                            : ''}
                    </p>
                    <div className={styles.horizontalDiv}></div>
                    <p>
                        <b>Fecha Radicación:</b>{' '}
                        {calcDate(new Date(ticket.creation_date))}
                    </p>
                    <div className={styles.horizontalDiv}></div>
                    <p>
                        <b>Estado: </b> {calcState(ticket.state.type)}
                    </p>
                    {ticket.resolution_date && (
                        <React.Fragment>
                            <div className={styles.horizontalDiv}></div>
                            <p>
                                <b>Fecha Cierre:</b>{' '}
                                {calcDate(new Date(ticket.resolution_date))}
                            </p>
                        </React.Fragment>
                    )}
                    {ticket.agents?.[0]?.name && (
                        <React.Fragment>
                            <div className={styles.horizontalDiv}></div>
                            <p>
                                <b>Responsable:</b> {ticket.agents?.[0]?.name}
                            </p>
                        </React.Fragment>
                    )}
                    {ticket.process && (
                        <div className={styles.processContainer}>
                            <ProcessRenderer ticket={ticket} />
                        </div>
                    )}
                </div>
                {ticket.form && (
                    <div className={styles.formBtnContainer}>
                        <RoundedButton
                            onClick={(): void => {
                                dispatch(setShowPostview(true));
                            }}
                            backgroundColor={branding?.colors.contrastColor}
                            text="Ver Formulario"
                        />
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}

export default Information;
