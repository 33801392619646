import React from 'react';
import { Route, Switch } from 'react-router-dom';
import TicketDetail from './pages/TicketDetail/TicketDetail';
import Home from './pages/Home/HomePage';
import Navbar from './shared/Navbar/Navbar';
import Loader from './shared/Loader/Loader';
import TicketTrace from './pages/TicketTrace/TicketTrace';

interface RouterComponentProps {
    /** If the app is mobile */
    mobile: boolean;
    /** If the app is visible */
    visible: boolean;
}
function RouterComponent({
    mobile,
    visible,
}: RouterComponentProps): JSX.Element {
    const urlParams = new URLSearchParams(window.location.search);
    const hideNav = urlParams.get('hide-nav');
    return (
        <React.Fragment>
            {!location.pathname.includes('trace') && !hideNav && (
                <Navbar mobile={mobile} />
            )}
            <div
                className="app"
                style={{
                    backgroundColor: mobile
                        ? 'var(--primary1)'
                        : 'var( --primary2)',
                    height: !!hideNav ? '100%' : undefined,
                }}
            >
                {!visible && (
                    <div className="mainLoader">
                        <Loader color={'var(--outlineGrey)'} />
                    </div>
                )}
                <Switch>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route exact path="/trace/:idTicket">
                        <TicketTrace />
                    </Route>
                    <Route path={['/tickets/:idTicket', '/survey/:idTicket']}>
                        <TicketDetail mobile={mobile} />
                    </Route>
                </Switch>
            </div>
        </React.Fragment>
    );
}
export default RouterComponent;
