import { AnyAction } from '@reduxjs/toolkit';
import { EditorState } from 'draft-js';
import maxSize from '../../constants/Files/MaxSize';
import * as Actions from './TextEditorActions';
export interface TextEditorState {
    editorState: EditorState;
    images: Record<string, ImageObject>;
    files: (FileObject | UploadedFileObject)[];
    filesError: boolean;
    focus: boolean;
}

export interface ImageObject {
    imageKey: string;
    file: File;
    state: 'FETCHING' | 'UPLOADING' | 'DONE' | 'CANCEL' | 'ERROR';
    localSrc: string;
    S3Key?: string;
    errorMsg?: string;
    uploadInfo?: any;
}

export interface FileObject {
    state: 'FETCHING' | 'UPLOADING' | 'DONE' | 'CANCEL' | 'ERROR';
    file: File;
    postInfo: any;
    fileName?: string;
    S3Key?: string;
    error?: boolean;
    errorMsg?: string;
}

export interface UploadedFileObject {
    fileName: string;
    S3Key: string;
}

const initialState = {
    editorState: EditorState.createEmpty(),
    focus: false,
    images: {},
    files: [],
    filesError: false,
};

/**
 * Redux Reducer that handles the state of the text editor
 * @param state The current state
 * @param action the action that was triggered
 * @returns the new state
 */
const TextEditorReducer = (
    state: TextEditorState = initialState,
    action: AnyAction
): TextEditorState => {
    if (Actions.reset.match(action)) {
        return {
            ...state,
            editorState: EditorState.createEmpty(),
            images: {},
            files: [],
            filesError: false,
        };
    } else if (Actions.setFocus.match(action)) {
        return { ...state, focus: action.payload };
    } else if (Actions.editorChange.match(action)) {
        return {
            ...state,
            editorState: action.payload,
        };
    } else if (Actions.uploadFiles.match(action)) {
        const files = [
            ...state.files,
            ...action.payload.map((file) => {
                return file.size > maxSize
                    ? ({
                          file,
                          state: 'ERROR',
                          errorMsg: 'El tamaño máximo de carga es de 30Mb.',
                      } as FileObject)
                    : ({
                          state: 'FETCHING',
                          file,
                          postInfo: null,
                      } as FileObject);
            }),
        ];
        return {
            ...state,
            filesError: state.filesError
                ? files.find((file: any) => !file.S3Key) !== undefined
                : false,
            files,
        };
    } else if (Actions.filesUploading.match(action)) {
        const newFiles = [...state.files];
        for (let i = 0; i < newFiles.length; i++) {
            const file = newFiles[i];
            for (const pFile of action.payload.errors) {
                if (
                    (file as FileObject).file === pFile.file &&
                    (file as FileObject).state !== 'ERROR'
                ) {
                    newFiles[i] = {
                        ...file,
                        errorMsg: pFile.errorMsg,
                        state: 'ERROR',
                    };
                }
            }
            for (const pFile of action.payload.fetched) {
                if ((file as FileObject).file === pFile.file) {
                    newFiles[i] = {
                        ...file,
                        postInfo: pFile.postInfo,
                        state: 'UPLOADING',
                    };
                }
            }
        }
        return {
            ...state,
            filesError: state.filesError
                ? newFiles.find((file: any) => !file.S3Key) !== undefined
                : false,
            files: newFiles,
        };
    } else if (Actions.fileDone.match(action)) {
        const files = state.files.map((file) =>
            (file as FileObject).file === action.payload.file
                ? {
                      ...file,
                      S3Key: action.payload.S3Key,
                      state: 'DONE',
                      fileName: action.payload.fileName,
                  }
                : file
        );
        return {
            ...state,
            filesError: state.filesError
                ? files.find((file: any) => !file.S3Key) !== undefined
                : false,
            files,
        };
    } else if (Actions.removeFile.match(action)) {
        const newFiles = [...state.files];
        for (let i = 0; i < newFiles.length; i++) {
            const file = newFiles[i];
            if ((file as FileObject).file === action.payload.file) {
                newFiles.splice(i, 1);
                break;
            }
        }
        return {
            ...state,
            filesError: state.filesError
                ? newFiles.find((file: any) => !file.S3Key) !== undefined
                : false,
            files: newFiles,
        };
    } else if (Actions.filesError.match(action)) {
        return { ...state, filesError: true };
    } else if (Actions.imageFetch.match(action)) {
        return {
            ...state,
            editorState: action.payload.editorState,
            images: {
                ...state.images,
                [action.payload.imageKey]: {
                    ...action.payload.image,
                    state: 'FETCHING',
                },
            },
        };
    } else if (Actions.imageUploadFile.match(action)) {
        return {
            ...state,
            images: {
                ...state.images,
                [action.payload.imageKey]: {
                    ...state.images[action.payload.imageKey],
                    uploadInfo: action.payload.uploadInfo,
                    state: 'UPLOADING',
                },
            },
        };
    } else if (Actions.imageDone.match(action)) {
        return {
            ...state,
            images: {
                ...state.images,
                [action.payload.imageKey]: {
                    ...state.images[action.payload.imageKey],
                    S3Key: action.payload.S3Key,
                    state: 'DONE',
                },
            },
        };
    } else if (Actions.imageError.match(action)) {
        return {
            ...state,
            images: {
                ...state.images,
                [action.payload.imageKey]: {
                    ...state.images[action.payload.imageKey],
                    errorMsg: action.payload.message,
                    state: 'ERROR',
                },
            },
        };
    }
    return state;
};
export default TextEditorReducer;
