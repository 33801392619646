import { RawDraftContentState } from 'draft-js';
import { PayloadImage } from '../@Types/Payload';
import { ImageObject } from '../controllers/TextEditorController/TextEditorReducer';
import { nanoid } from 'nanoid';
export function stringToDraft(text: string): RawDraftContentState {
    let draftStructure = {
        blocks: [] as any[],
        entityMap: {},
    };
    text.split('\n').forEach((element, index) => {
        draftStructure.blocks.push({
            key: String(index),
            text: element,
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
        });
    });
    return draftStructure as RawDraftContentState;
}

export const getRawText = (
    draft?: RawDraftContentState,
    text?: string
): RawDraftContentState => {
    if (draft !== undefined) {
        return draft;
    } else if (text !== undefined) {
        return stringToDraft(text);
    } else {
        return {
            blocks: [
                {
                    key: 'fudmo',
                    text: '',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [],
                    data: {},
                },
            ],
            entityMap: {},
        };
    }
};

/**
 *
 * @param currentContent
 * @returns if the images in a draft content are valid.
 */
export const getValidImages = (
    currentContent: any,
    storeImages: Record<string, ImageObject>
): Record<string, PayloadImage> => {
    const images: Record<string, PayloadImage> = {};
    const blocks = currentContent.getBlocksAsArray();
    for (const block of blocks) {
        if (block.getType() === 'atomic') {
            const entityKey = block.getEntityAt(0);
            const entity = currentContent.getEntity(entityKey);
            const { imageKey } = entity.getData();
            if (entity.getType() === 'IMAGE') {
                const storeImage = storeImages[imageKey];
                if (storeImage.S3Key === undefined) {
                    throw Error('INVALID IMAGE');
                } else {
                    images[imageKey] = {
                        imageKey,
                        S3Key: storeImage.S3Key,
                    };
                }
            }
        }
    }
    return images;
};

/**
 * Trims empty blocks around an image if it is sent in the beggining or at the end
 */
export const draftRawTrim = (
    original: RawDraftContentState
): RawDraftContentState => {
    const draft = { ...original };
    if (
        draft.blocks.length > 2 &&
        draft.blocks[1].type === 'atomic' &&
        draft.blocks[1].text === ' ' &&
        draft.blocks[1].entityRanges.length > 0 &&
        draft.blocks[0].type === 'unstyled' &&
        draft.blocks[0].text === ''
    ) {
        draft.blocks.splice(0, 1);
    }
    const newLength = draft.blocks.length;
    if (
        newLength > 1 &&
        draft.blocks[newLength - 2].type === 'atomic' &&
        draft.blocks[newLength - 2].text === ' ' &&
        draft.blocks[newLength - 2].entityRanges.length > 0 &&
        draft.blocks[newLength - 1].type === 'unstyled' &&
        draft.blocks[newLength - 1].text === ''
    ) {
        draft.blocks.splice(newLength - 1, 1);
    }
    return draft;
};

export const calcImageEntityData = async (
    localSrc: string
): Promise<{ ratio: number; width: any; height: any; imageKey: string }> => {
    const imageKey = nanoid();
    const image = await loadImage(localSrc);
    const ratio = image.width / image.height;
    let width = image.width;
    let height = image.height;
    //width > height
    if (ratio > 1) {
        if (width > 330) {
            width = 330;
        }
    } else {
        if (height > 300) {
            width = 300 * ratio;
        }
    }
    return {
        ratio,
        height,
        width,
        imageKey,
    };
};

export const loadImage = (src: string): any =>
    new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = (): any => resolve(img);
        img.onerror = reject;
        img.src = src;
    });
