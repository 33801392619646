import { createAction } from '@reduxjs/toolkit';
import { Organization } from '../../@Types/@Types';
import { Branding } from '../../@Types/Branding';

export const Types = {
    LOAD: 'SITE/LOAD',
    BRANDING: 'SITE/BRANDING',
    LOAD_ERROR: 'SITE/LOAD_ERROR',
    LOAD_SUCCESS: 'SITE/LOAD_SUCCESS',
};

/**
 * Called when the app is first loaded and tokens were found
 */
export const load = createAction(Types.LOAD);

/**
 * Called after the first load, includes all the necesary info for the first load.
 */
export const loadSuccess = createAction<Organization>(Types.LOAD_SUCCESS);

/**
 * Called to update the branding of the site
 */
export const setBranding = createAction<Branding>(Types.BRANDING);

/**
 * Called when the org failed to load
 */
export const loadError = createAction(Types.LOAD_ERROR);
