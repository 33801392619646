import { createAction } from '@reduxjs/toolkit';
import { EditorState } from 'draft-js';
import { FileObject, ImageObject } from './TextEditorReducer';
export const Types = {
    FOCUS: 'FOCUS',
    RESET: 'TEXT_EDITOR/RESET',
    CHANGE: 'TEXT_EDITOR/CHANGE',
    UPLOAD_FILE: 'TEXT_EDITOR/UPLOAD_FILE',
    FILES_UPLOAD_FILES: 'TEXT_EDITOR/FILES_UPLOAD_FILES',
    FILE_DONE: 'TEXT_EDITOR/FILE_DONE',
    FILES_REMOVE: 'TEXT_EDITOR/FILES_REMOVE',
    FILES_ERROR: 'TEXT_EDITOR/FILES_ERROR',
    UPLOAD_IMAGE: 'TEXT_EDITOR/UPLOAD_IMAGE',
    IMAGE_FETCH: 'TEXT_EDITOR/IMAGE_FETCH',
    IMAGE_UPLOAD_FILE: 'TEXT_EDITOR/IMAGE_UPLOAD_FILE',
    IMAGE_DONE: 'TEXT_EDITOR/IMAGE_DONE',
    IMAGE_ERROR: 'TEXT_EDITOR/IMAGE_ERROR',
};
/**
 * Called to toggle the focus
 */
export const setFocus = createAction(
    Types.FOCUS,
    function prepare(loading: boolean = true) {
        return {
            payload: loading,
        };
    }
);

/**
 * Called when the text editor info needs resetting
 */
export const reset = createAction(Types.RESET);

/**
 * Called a change ocurrs in the editorState
 */
export const editorChange = createAction<EditorState>(Types.CHANGE);

/**
 * Called when the user wants to upload files
 */
export const uploadFiles = createAction<File[]>(Types.UPLOAD_FILE);

/**
 * Called when files need to fetch de upload url
 */
export const filesUploading = createAction<{
    errors: { file: File; errorMsg: string }[];
    fetched: { file: File; postInfo: any }[];
}>(Types.FILES_UPLOAD_FILES);

/**
 * Called when an file finishes uploading
 */
export const fileDone = createAction<{
    file: File;
    S3Key: string;
    fileName: string;
}>(Types.FILE_DONE);

/**
 * Called when the user wants to remove a file from the list
 */
export const removeFile = createAction<FileObject>(Types.FILES_REMOVE);

/**
 * Called when the files have an error
 */
export const filesError = createAction(Types.FILES_ERROR);

/**
 * Called when an image needs to fetch de upload url
 */
export const uploadImage = createAction<File>(Types.UPLOAD_IMAGE);

/**
 * Called when an image needs to fetch de upload url
 */
export const imageFetch = createAction<{
    imageKey: string;
    image: ImageObject;
    editorState: EditorState;
}>(Types.IMAGE_FETCH);

/**
 * Called when an image starts to upload
 */
export const imageUploadFile = createAction<{
    imageKey: string;
    uploadInfo: any;
}>(Types.IMAGE_UPLOAD_FILE);

/**
 * Called when an image finishes uploading
 */
export const imageDone = createAction<{
    imageKey: string;
    S3Key: string;
}>(Types.IMAGE_DONE);

/**
 * Called when an image throws an error on upload
 */
export const imageError = createAction<{
    imageKey: string;
    message: string;
}>(Types.IMAGE_ERROR);
