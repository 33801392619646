import styles from './DateActivityAction.module.css';
import EventRoundedIcon from '@material-ui/icons/EventRounded';
import React from 'react';
import { format } from 'date-fns';
import { getLocale } from '../../../../..';
import { Branding } from '../../../../../@Types/Branding';
interface DateActivityActionProps {
    date: Date;
    branding: Branding;
    cancelled: boolean;
}

function DateActivityAction({
    date,
    branding,
    cancelled = false,
}: DateActivityActionProps): JSX.Element {
    let dateFormat = 'PPPp';

    const formattedDate = format(date, dateFormat, {
        locale: getLocale(),
    });

    return (
        <div className={styles.container}>
            <div className={styles.dateContainer} title={formattedDate}>
                <div className={styles.iconContainer}>
                    <EventRoundedIcon
                        fontSize="inherit"
                        style={{ color: branding.colors.primaryColor }}
                    />
                </div>
                <label
                    className={styles.dateLbl}
                    style={
                        cancelled
                            ? {
                                  textDecoration: 'line-through',
                              }
                            : {}
                    }
                >
                    {formattedDate}
                </label>
            </div>
        </div>
    );
}
export default DateActivityAction;
