import { Avatar as MaterialAvatar } from '@material-ui/core';
interface AvatarProps {
    /** Size of the avatar */
    size?: number;
    /** Img src of the avatar */
    img?: string;
    /** Text on avatar */
    lbl: string;
    /** If shows text on hover */
    toolTip?: boolean;
    /** Change background color */
    color?: string;
    /** Rednder Avatar with material ui icon */
    children?: React.ReactNode;
    /** The size of the font to display */
    fontSize?: number;
}
/**
 * Component for custom avatar
 */
function Avatar({
    size = 30,
    fontSize = 20,
    img,
    lbl,
    toolTip = false,
    color = 'var(--secondary)',
    children,
}: AvatarProps): JSX.Element {
    return (
        <MaterialAvatar
            style={{
                width: size,
                height: size,
                backgroundColor: img ? 'transparent' : color,
                color: 'white',
                fontSize,
            }}
            alt={lbl}
            title={toolTip ? lbl : undefined}
            src={img ? img : '/'}
        >
            {children}
        </MaterialAvatar>
    );
}

export default Avatar;
