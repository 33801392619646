import LikeRating from './Ratings/LikeRating';
import ScaleRating from './Ratings/ScaleRating';
import SatisfactionRating from './Ratings/SatisfactionRating';
import { RatingTypes } from '../../constants/SurveyTypes';

export interface RatingProps {
    disabled?: boolean;
    name: string;
    isMobile?: boolean;
    value: any;
    onChange: Function;
    inputRef: any;
    focusColor: string;
    unSelectedColor: string;
    isAction?: boolean;
}

function Rating({
    type,
    ...others
}: RatingProps & { type: RatingTypes }): JSX.Element {
    switch (type) {
        case RatingTypes.LIKE:
            return <LikeRating {...others} />;
        case RatingTypes.SATISFACTION:
            return <SatisfactionRating {...others} />;
        case RatingTypes.SCALE:
            return <ScaleRating {...others} />;
        default:
            return <div></div>;
    }
}

export default Rating;
